@import "../../../../../app/Variables.less";
// src/components/HR/Surveys/Survey/SurveyApp/SurveyApp.less
.survey-results-table {
  tr.ant-table-expanded-row.ant-table-expanded-row-level-1 {
    display: none !important;
  }
}
.ant-table-row {
  &.expanded-row {
    background: @purple-100 !important;
    background: @purple-100 !important;
    .ant-table-cell-fix-right,
    .ant-table-cell-fix-left {
      background: @purple-100 !important;
    }
  }
}
.ant-table-thead
  .ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-thead
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: @emerald-100 !important;
}
.survey-results-checkbox {
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #1890ff !important;
      border-color: #1890ff !important;
    }
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #ebf6ff;
  }
}

@primary-color: #20384b;