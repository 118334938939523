@import "../../../app/Variables.less";

.horizontal.attribute {
  .attribute-name {
    text-align: left;
    color: rgba(55, 53, 47, 0.6);
  }
}
.attribute {
  font-family: monospace, monospace !important;
  line-height: 24px;
  min-width: 0px;
  transition: 0.2s ease-in background;
  &.vertical {
    display: block;
    height: auto;
  }
  .mono-old {
    font-family: "Courier Prime", monospace !important;
  }
  .attribute-value-number,
  .attribute-value-percentage {
    font-family: "Courier Prime", serif !important;
    * {
      font-family: "Courier Prime", serif !important;
    }
  }
  .attribute-money-value {
    min-width: 80px;
    /*text-align:right;*/
    /*font-style:italic;*/
    font-family: "Courier Prime", serif !important;
  }
  .attribute-value.attribute-checkbox {
    box-shadow: none !important;
    background: transparent !important;
  }
  .attribute-value .ant-select:active,
  .attribute-value .ant-select:focus {
    outline: none !important;
  }
  .attribute-value .ant-select {
    width: 100% !important;
  }
  .attribute-value .ant-select-dropdown {
    .ant-select-item {
      &.ant-select-item-option-selected {
        background: @selected-color;
      }
      background: @form-bg-no-opacity;
    }
  }
  .attribute-value .ant-select-selector {
    border: none;
    background: transparent !important;
    width: 100%;
  }
  .attribute-value.editable {
    display: flex;
    align-items: center;
    font-size: 15px;
    padding: 1px 0px !important;
    position: relative;
    border-radius: 3px;
    box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px inset,
      rgba(15, 15, 15, 0.1) 0px 1px 1px inset;
    background: @form-bg;
    cursor: text;

    textarea.attribute-input,
    div.attribute-input,
    input.attribute-input {
      min-height: 23px;
      padding-left: 5px;
      width: 100%;
      resize: none;
      outline: none !important;
      margin: 0px;
      background: transparent;
      border: none;
    }
    &.non-editing-mode {
      background: fade(@form-bg, 50%) !important;
    }
    &.disabled {
      box-shadow: rgba(15, 15, 15, 0.03) 0px 0px 0px 1px inset,
        rgba(15, 15, 15, 0.03) 0px 1px 1px inset;
    }
    &.readonly {
    }
  }
  .attribute-name {
    text-align: left;
    color: @gray-700;
    margin-right: 8px;
    font-weight: bold;
    &.attribute-name-wide {
      width: unset;
    }
    &.attribute-name-inline {
      width: auto !important;
      white-space: nowrap;
    }
  }
  .attribute-selectable:hover {
    cursor: pointer;
    background: rgba(55, 53, 47, 0.08);
  }
  .attribute-title {
    color: rgba(0, 0, 0, 0.7);
    font-size: 14px;
  }
  .attribute-content {
    font-size: 18px;
  }
}

.attribute-overlay .ant-dropdown-menu {
  margin-top: -3px !important;
  width: 220px;
}
.minimal-clickable .attribute-value:hover {
  background: transparent;
}

@primary-color: #20384b;