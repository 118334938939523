@import "../../../app/Variables.less";
.login-form .ant-form-item-explain {
  display: none;
}
.login-form-forgot {
  text-align: right;
  display: block;
  height: 15px;
}
.login-logo {
  position: absolute;
  right: 20px;
  width: 200px;
  bottom: 20px;
  z-index: -10;
}
.login-card {
  width: 340px;
  .ant-card-body {
    padding: 36px;
  }
}
.login-card {
  .ant-form-item-control-input {
    line-height: 12px;
  }
}
.login-card .ant-checkbox-wrapper {
  display: flex;
}
.login-form-header {
  margin-bottom: 24px;
  font-size: 18px;
}
.login-vert {
  display: flex;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
  flex-flow: column;
}
.login-horiz {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-prefix {
  color: rgba(0, 0, 0, 0.25);
}
.custom-login-page {
  position: fixed;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
  background-blend-mode: color !important;
}

.login-page {
  background-color: #6924c4;
  position: fixed;
  width: 100%;
  height: 100%;
  background-size: cover;
}
.ant-card.login-card {
  background: rgba(255, 255, 255, 1);
  border: none;
}
.login-card {
  .ant-input-affix-wrapper {
    border-radius: 4px !important;
    height: 32px;
  }
}
.pw-validation:before {
  content: "●";
  margin-right: 0.5rem;
}
.pw-validation.pass {
  color: #66bb6a;
}
.pw-validation.pass:before {
  content: "✓";
}
.login-page .ant-checkbox-inner {
  border-color: @blue-700 !important;
}
.login-page .ant-checkbox-checked .ant-checkbox-inner {
  background: @blue-700 !important;
  border-color: @blue-700 !important;
}
.login-bg-img {
  transform: scale(1.01);
  transition: all ease-in-out 0.2s;
  &.loading {
    filter: blur(2px);
  }
}

@primary-color: #20384b;