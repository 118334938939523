.hr-header {
  &.no-title {
    min-height: auto;
  }
  h2.ant-typography {
    margin-bottom: 0px;
  }
  .ant-typography {
    color: #444;
  }
  h2 .anticon {
    margin-right: 10px !important;
    color: #444;
  }
  .ant-tabs-nav,
  .ant-tabs-bar.ant-tabs-top-bar {
    margin: 0px;
  }
}
.header-right {
  position: absolute;
  top: 25px;
  right: 40px;
}
.tabs-right {
  padding-top: 8px;
}
.ant-tabs-top {
  &.no-separator {
    .ant-tabs-nav::before {
      display: none;
    }
  }
}

@primary-color: #20384b;