.collapse-header-container.collapse-open {
  border-radius: 8px 8px 0 0;
}
.collapse-card {
  border-radius: 8px;
}
.collapse-header-container {
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 2px 8px;
}
.collapse-header-container,
.collapse-toolbar {
  background: transparent;
  color: white;
}
.collapse-header {
  line-height: 32px;
  font-variant: small-caps;
}
.collapse-icon {
}
.collapse-toolbar {
  display: flex;
  justify-content: space-around;
}
.collapse-toolbar .ant-btn,
.collapse-header-container .ant-btn {
  border: none;
  color: #6679a3;
  box-shadow: none;
  background: transparent;
}
.collapse-header-container .ant-btn.down {
  transform: rotate(90deg);
}
.collapse-header-container .ant-btn.left {
  transform: rotate(180deg);
}
.collapse-content {
  padding: 8px;
}
/* marginTop:0, */
/* borderRadius:4, */
/* border:'none', */
/* boxShadow:'2px 2px 2px rgba(0,0,0,0.05)', */

.collapse-toolbar > div {
  display: contents;
}
.collapse-toolbar {
  padding: 0 0 5px;
}
.collapse-toolbar .ant-btn {
  font-size: 14px;
  width: 22px;
  height: 22px;
}
