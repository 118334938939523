@import "../Variables.less";
#campushr .ant-dropdown input:focus {
  margin-top: 5px;
  line-height: 26px;
  border-radius: 3px;
  box-shadow: @inset-shadow;
  background: @form-bg;
  border: none !important;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background: @button-hover-bg !important;
}
/* disable hover background change on search item */
.editable-attribute
  .ant-dropdown-menu
  .ant-dropdown-menu-item:first-child:hover {
  background: none !important;
}
.editable-attribute .ant-dropdown-menu,
#dropdown-container .ant-dropdown-menu {
  margin: 0 !important;
  padding: 4px 0;
  box-shadow: @drop-shadow;
}
#dropdown-container .ant-dropdown-menu-item input,
.minimal-dropdown .ant-dropdown-menu-item input {
  margin-bottom: 8px;
}
#dropdown-container .ant-dropdown-menu-item,
.minimal-dropdown .ant-dropdown-menu-item {
  padding: 2px 12px !important;
}
#campushr span.ant-input-affix-wrapper {
  border-radius: 4px;
}
.ant-table-tbody > tr > td {
  padding: 4px !important;
  font-size: 0.9rem !important;
}
.fullscreen-enabled .ant-card {
  height: 100vh;
}

.ant-form-item .ant-input-affix-wrapper:focus-within {
  & .ant-input,
  & .ant-input:active,
  & .ant-input:focus {
    border: none !important;
  }
  border: 1px solid @blue-400 !important;
  &:active {
    border: 1px solid @blue-400 !important;
  }
}
.ant-form-item .ant-input.ant-input-sm {
  border: 1px solid #e1e4e6 !important;
  &:focus,
  &:active {
    border: 1px solid @blue-400 !important;
  }
}
.ant-input {
  background: transparent !important;
}

.ant-input-affix-wrapper {
  box-shadow: none !important;
}

.ant-card,
.ant-collapse,
.ant-card.floating-card,
.ant-collapse.floating-card {
  border-radius: 6px;
  border: none !important;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.07);
}

.card-padding-6 .ant-card-body {
  padding: 6px;
}
.card-padding-0 .ant-card-body {
  padding: 0;
  border-radius: 4px;
  overflow: hidden;
}
.card-padding-12 .ant-card-body {
  padding: 12px;
}
.card-padding-0 .ant-card-body {
  padding: 0px;
}
.small-table .ant-table-column-sorters .ant-table-cell,
.table-xs .ant-table-thead > tr > th,
.small-table .ant-table-tbody > tr > td {
  padding: 2px !important;
}
.table-xs .ant-table-thead > tr > th {
  padding: 6px;
}
.error-table {
  .ant-table {
    .ant-table-tbody {
      td.ant-table-cell {
        padding: 0 4px !important;
      }
      .cell-error,
      .cell-normal {
        padding: 4px;
      }
      .row-empty-values {
        background-color: @red-100 !important;
        color: @red-400;
        .cell-error {
          border: 2px solid @red-600 !important;
          background-color: @red-300 !important;
          color: @red-900;
        }
        &:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
          background: @red-200 !important;
        }
      }
    }
  }
}
.table-xs .ant-table-thead > tr > th,
.table-xs .ant-table-tbody > tr > td {
  font-size: 0.75rem !important;
  overflow: hidden;
  white-space: nowrap;
}
// .table-xs .ant-table-tbody > tr > td {
//   min-width: 100px;
// }
.ant-table-thead
  .ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-thead
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: @table-hover-bg !important;
}

.ant-table-thead > tr > th,
thead > tr > th {
  border-top: 1px solid #f0f0f0;
  background-color: #fff; // ojo: el important me modifica mis tablas! !important;
  /*background-color: #fff !important;*/
  font-weight: 600 !important;
  &:hover {
    background-color: darken(#fff, 3%); // !important;
  }
}
.ant-table-tbody {
  tr {
    &.ant-table-placeholder {
      td {
        background-color: #fff;
        &:hover {
          background-color: darken(#fff, 3%) !important;
        }
        .ant-empty {
          color: rgba(0, 0, 0, 0.8);
          font-weight: 600;
        }
      }
    }
  }
}
.ant-table-wrapper {
  .ant-spin {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
.ant-card-meta-title {
  font-weight: 600 !important;
}
.ant-card-head {
  .ant-card-head-title {
    font-weight: 600 !important;
  }
  .anticon {
    vertical-align: middle;
  }
}
.anticon {
  vertical-align: middle !important;
}
.ant-btn {
  border-radius: 4px !important;
}
.ant-modal-content {
  border-radius: 6px !important;
}
.ant-modal-header {
  border-radius: 6px !important;
}
.head-margin-right-16 .ant-card-head-title {
  margin-right: 16px !important;
  white-space: initial !important;
}
.head-margin-right .ant-card-head-title {
  margin-right: 200px !important;
  white-space: initial !important;
}
.ant-table-tbody > tr > td.table-column-left {
  padding-left: 8px !important;
}
.ant-table-tbody > tr > td.table-column-right {
  padding-right: 8px !important;
}
.ant-table {
  .ant-table-tbody {
    tr {
      td {
        font-size: 0.75rem;
      }
    }
  }
}

.ant-btn-circle,
.ant-btn-circle-outline {
  border-radius: 50% !important;
}
.ant-tag {
  color: white;
  letter-spacing: 0.2px;
  font-weight: 600 !important;
  line-height: 18.5px !important;
  height: 20px !important;
  font-size: 0.625rem !important;
  text-transform: uppercase;
  div {
    .flex-center();
  }
  code {
    background: transparent;
  }
}
.ant-card {
  padding: 0px !important;
  border-radius: 6px !important;
  .ant-card-head {
    border-radius: 6px;
    padding: 0 20px;
    .ant-card-head-wrapper {
      .ant-card-head-title {
        border-radius: 6px;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.75);
        letter-spacing: 0.5px;
        font-weight: bold;
        padding: 10px 0px;
        font-size: 18px;
      }
      .ant-card-extra {
        margin-right: 10px;
        .anticon {
          font-size: 18px;
        }
      }
    }
  }
}
.ant-tooltip {
  &.error-tooltip {
    .ant-tooltip-inner {
      background-color: @red-500;
      box-shadow: 0 3px 6px -4px rgba(180, 5, 5, 0.12),
        0 6px 16px 0 rgba(180, 5, 5, 0.08), 0 9px 28px 8px rgba(180, 5, 5, 0.05);
    }
    .ant-tooltip-arrow {
      &:before,
      .ant-tooltip-arrow-content {
        background-color: @red-500;
      }
    }
  }
  &.custom-tooltip {
    .ant-tooltip-inner {
      background-color: @blue-600;
      box-shadow: 0 3px 6px -4px rgba(79, 175, 195, 0.12),
        0 6px 16px 0 rgba(79, 175, 195, 0.08),
        0 9px 28px 8px rgba(79, 175, 195, 0.05);
    }
    .ant-tooltip-arrow {
      &:before,
      .ant-tooltip-arrow-content {
        background-color: @blue-600;
      }
    }
  }
}
.ant-tooltip {
  .ant-tooltip-inner {
    background-color: @primary-600;
    box-shadow: 0 3px 6px -4px fade(@primary-600, 0.05%),
      0 6px 16px 0 fade(@primary-600, 0.05%),
      0 9px 28px 8px fade(@primary-600, 0.05%) !important;
  }
  .ant-tooltip-arrow-content {
    background-color: @primary-600;
  }
}

@popover-bg: @secondary-500;
@popover-border: #42a1ee;
@popover-text-content: white;
@popover-text-title: white;

/*@popover-bg: white;*/
/*@popover-border: @gray-100;*/
/*@popover-text-content: black;*/
/*@popover-text-title: @secondary-500;*/

.ant-popover {
  &.custom-popover {
    @media (min-width: 992px) {
      max-width: 50vw;
    }
    .ant-popover-content {
      @media (min-width: 992px) {
        width: 100%;
      }
      .ant-popover-inner {
        border-radius: 6px;
        background-color: @popover-bg;
        .ant-popover-title {
          font-weight: bold;
          color: @popover-text-title;
        }
        .ant-popover-inner-content {
          color: @popover-text-content;
        }
        .ant-popover-title {
          border-bottom: 1px solid @popover-border;
          font-size: 1.25rem;
        }
        .ant-popover-inner-content {
          font-size: 0.875rem;
        }
      }
      .ant-popover-arrow {
        background-color: @popover-bg;
        border-right-color: @popover-bg;
        border-bottom-color: @popover-bg;
      }
    }
  }
  &.light-popover {
    @media (min-width: 992px) {
      max-width: 50vw;
    }
    .ant-popover-content {
      @media (min-width: 992px) {
        width: 100%;
      }
      .ant-popover-inner {
        border-radius: 6px;
        .ant-popover-title {
          font-weight: bold;
          font-size: 1.05rem;
          background: #f7f9fa;
          color: #333;
        }
        .ant-popover-inner-content {
          font-size: 0.875rem;
        }
      }
      .ant-popover-arrow {
        background-color: white;
      }
    }
  }
}
.desktop-add {
  padding-top: 6px;
  .ant-btn-primary {
    margin-left: 10px;
  }
  @media (max-width: 767px) {
    display: none;
  }
}
.ant-message {
  .ant-message-notice {
    .ant-message-notice-content {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 0;
      .ant-message-custom-content {
        border-radius: 4px !important;
        padding: 10px 15px;
        color: #fff;
        .anticon {
          svg {
            fill: #fff !important;
          }
        }
        &.ant-message-success {
          background-color: @success-color;
        }
        &.ant-message-error {
          background-color: @danger-color;
        }
        &.ant-message-warning {
          background-color: @warning-600;
        }
      }
    }
  }
}
.ant-select-show-search input.ant-select-selection-search-input {
  border: none !important;
}

.ant-form-item .ant-picker-input {
  border: none !important;
  & input {
    border: none !important;
  }
}
.ant-form-item .ant-input {
  border: none !important;
}
.ant-input-textarea {
  &.ant-input-textarea-show-count {
    &::after {
      font-size: 0.75rem;
    }
  }
}
.ant-select-dropdown {
  background: @form-bg-no-opacity;
  color: #333;
  box-shadow: @inset-shadow;
  border: 1px solid mix(@dark-primary, @form-bg);
  border-radius: 2px;
}
.ant-form-item-control-input-content > textarea {
  border: 1px solid #e1e4e6 !important;
}
.ant-select-item-option,
.ant-select-item {
  border-bottom: 1px solid mix(@dark-primary, @form-bg, 5%);
  background: @form-bg;
  box-shadow: none !important;
  min-height: initial;
  height: 28px;
  .ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
    border: 1px solid #eee !important;
    padding: 1px;
  }
  &.ant-select-item-option-selected,
  &.ant-select-item-option-active {
    background: @primary-200 !important;
    color: @primary-700 !important;
  }
}
.ant-select-dropdown {
  .ant-select-item.ant-select-item-option {
    background: white !important;
    border-color: #fafafa;
    &:hover {
      background: @emerald-100 !important;
    }
  }
}

.ant-select-focused {
  border: none !important;
  outline: none !important;
}

.ant-select.ant-select-sm .ant-select-selector {
  min-height: 25px !important;
}
.ant-modal-content .ant-selector {
  .hr-form();
}

// .ant-upload.ant-upload-drag:not(&.files-dragger),
.ant-upload.ant-upload-drag,
.ant-picker,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-textarea-with-clear-btn),
.ant-input,
.ant-input-password,
.ant-select-selector,
.ant-input-number,
textarea,
input:hover,
input:active,
input:focus,
input {
  .hr-form();
}
.ant-form-item {
  margin-bottom: 8px;
}
.ant-upload.ant-upload-drag.transparent {
  background: transparent !important;
}
/* Fix selector line-height for labels */
#campushr
  .ant-form-item-control-input
  .ant-select-selector
  .ant-select-selection-item {
  & div {
    line-height: unset !important;
  }
  line-height: unset !important;
  & .table-select-aux-value {
    line-height: 24px !important;
  }
}
#campushr .ant-form-item-control-input-content .ant-input-affix-wrapper input {
  border: none !important;
}
.ant-input-affix-wrapper > .ant-input {
  border: none !important;
  &:focus,
  &:active,
  &:hover {
    border: none !important;
  }
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn
  > .ant-input {
  height: initial;
}
.ant-input-affix-wrapper > .ant-input {
  height: 22px;
}
.ant-input {
  height: 26px;
  border: 1px solid #e1e4e6 !important;
  &:focus,
  &:active {
    border: 1px solid @blue-400 !important;
  }
  &:hover {
    border: 1px solid #e1e4e6 !important;
  }
}
.ant-picker,
.ant-form-item-control-input-content .ant-input-affix-wrapper,
.ant-form-item-control-input-content input,
.ant-input-number input,
.ant-select-selector,
.minimal-search-bar .ant-input-affix-wrapper {
  border: 1px solid #e1e4e6 !important;
}
.ant-input-affix-wrapper,
.ant-picker {
  input {
    box-shadow: none !important;
  }
}
.ant-select {
  width: 100%;
}
.ant-select-selector {
  height: 30px !important;
  input {
    box-shadow: none !important;
    box-sizing: border-box !important;
    background: transparent !important;
  }
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 22px !important;
}
.ant-input-number {
  .ant-input-number-handler {
    .hr-form();
  }
}
.ant-form-item-has-error {
  .ant-select-selection-item {
    line-height: 260px !important;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 22px !important;
  }
  .ant-input-number {
    .ant-input-number-handler {
      .hr-form();
    }
  }
  .ant-form-item-has-error {
    .ant-input-affix-wrapper,
    .ant-picker {
      input {
        box-shadow: none !important;
      }
    }
    .ant-form-item-control-input-content {
      div {
        .ant-picker-clear {
          background: green !important;
        }
      }
    }
  }
  // .ant-upload-drag-container {
  //   p {
  //     color: @danger-color !important;
  //     svg {
  //       color: @danger-color !important;
  //     }
  //   }
  // }
  // ::placeholder {
  //   color: @danger-color !important;
  // }
}
// this is for dragger preview
.ant-upload.ant-upload-drag {
  transition: all 0.3s ease !important;
  .ant-upload {
    padding: 0 !important;
    border-radius: 2px;
    min-height: 150px !important;
    &.ant-upload-btn {
      .flex-center();
      .ant-upload-drag-container {
        width: 100%;
      }
    }
  }
  &.files-dragger {
    background: rgba(233, 233, 233, 0.2) !important;
    border: 1px dashed #ababab !important;
    .hover-info {
      display: none;
    }
    &.ant-upload-drag-hover {
      // box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.19);
      border-color: @hr-blue !important;
      .hover-info {
        display: block;
      }
    }
  }
}
.ant-select.attribute-select.ant-select-open {
  outline: none !important;
}
/* Ant forms */
.ant-form {
  .ant-form-item {
    margin: 0 0 5px;
    padding: 0;
    .ant-form-item-label {
      padding: 0 !important;
      label {
        font-weight: 600;
      }
    }
  }
}
.ant-picker {
  width: 100%;
}
.ant-picker-clear {
  background: @form-bg !important;
}
/* end Ant forms */

/* Ant collapse */
.ant-collapse {
  border-radius: 4px !important;
  .ant-collapse-item {
    border-bottom: 0 !important;
    &.header-white {
      .ant-collapse-header {
        background-color: #fff;
      }
    }
  }
  .ant-collapse-content {
    border-top: 0 !important;
  }
}
/* end Ant collapse */

/* ant progress */
// .ant-progress {
//   .ant-progress-outer {
//     .ant-progress-inner {
//       /*box-shadow: @inset-shadow;*/
//     }
//   }
// }
/* end ant progress */

/* ant slider */
.ant-slider {
  .ant-slider-rail {
    box-shadow: @inset-shadow;
  }
}
/* end ant slider */
/* input group */
.ant-input-group-wrapper {
  .ant-input-group {
    .ant-input-group-addon {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-width: 1px 0 1px 1px !important;
    }
    .ant-input-affix-wrapper {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}
/* input group */

.font-mono.ant-input-affix-wrapper input,
.ant-input-password.ant-input-affix-wrapper input {
  border-width: 0px !important;
}
.card-container > .ant-tabs-card > .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}
.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
.ant-input-number {
  input {
    font-family: "B612", "Courier Prime", Menlo, Monaco, Consolas,
      "Liberation Mono", "Courier New", monospace !important;
  }
  width: 100% !important;
  background: transparent !important;
}
.info-alert {
  border-radius: 4px !important;
  background-color: @blue-100 !important;
  color: @blue-700 !important;
  .ant-alert-icon,
  .ant-alert-with-description,
  .ant-alert-message {
    color: @blue-700 !important;
  }
  .ant-alert-message {
    font-weight: 700;
  }
  &.hor {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .ant-alert-message {
      margin-right: 25px;
    }
  }
  &.big-text {
    .ant-alert-message {
      font-size: 16px !important;
    }
  }
}
.danger-alert {
  border-radius: 4px !important;
  background-color: @red-100 !important;
  color: @red-500 !important;
  .ant-alert-icon,
  .ant-alert-with-description,
  .ant-alert-message {
    color: @red-500 !important;
  }
  .ant-alert-message {
    font-weight: 700;
  }
  &.white-bg {
    background-color: #fff !important;
    border: none !important;
  }
  &.text-left {
    text-align: left !important;
    .ant-alert-message {
      text-align: left !important;
    }
  }
}
.warning-alert {
  border-radius: 4px !important;
  background-color: @yellow-200 !important;
  color: @yellow-500 !important;
  .ant-alert-icon,
  .ant-alert-with-description,
  .ant-alert-message {
    color: @yellow-500 !important;
  }
  .ant-alert-message {
    font-weight: 700;
  }
  &.white-bg {
    background-color: #fff !important;
    border: none !important;
  }
  &.text-left {
    text-align: left !important;
    .ant-alert-message {
      text-align: left !important;
    }
  }
}
.success-alert {
  border-radius: 4px !important;
  background-color: @green-200 !important;
  color: @green-500 !important;
  .ant-alert-icon,
  .ant-alert-with-description,
  .ant-alert-message {
    color: @green-500 !important;
  }
  .ant-alert-message {
    font-weight: 700;
  }
  &.white-bg {
    background-color: #fff !important;
    border: none !important;
  }
}
.ant-alert {
  border-radius: 4px;
  &.ant-alert-error {
    background: #fcdddc;
    border: none;
    text-align: center;
    .ant-alert-message {
      font-weight: bold;
      color: #ef5350;
      font-size: 12px;
      text-align: center;
    }
  }
  &.ant-alert-info {
    &.light {
      background: @teal-100;
      color: @teal-500;
    }
    background: @teal-100;
    border: none;
    /*text-align: center;*/
    text-align: justify !important;
    text-justify: inter-word !important;
    .ant-alert-message {
      font-weight: bold;
      color: @teal-800;
      font-size: 12px;
      text-align: center;
    }
  }
}
.ant-collapse-header .minimal-toolbar {
  top: 22px;
  right: 40px;
}
.ant-badge.hr {
  .ant-badge-status-dot {
    width: 10px;
    height: 10px;
    background: @red-300;
  }
}
.App .ant-layout {
  background: @body-bg;
}
.ant-table-thead > tr > th {
  padding: 6px;
}

.ant-picker-cell-selected .ant-picker-calendar-date,
.ant-picker-cell-selected:hover .ant-picker-calendar-date,
.ant-picker-cell-selected .ant-picker-calendar-date-today,
.ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
  background: #b0ce664f !important;
}
.ant-picker-dropdown .ant-picker-content tr {
  height: 45px;
}
/* Menu sidebar without bg */
.menu-no-bg-wrapper {
  .ant-menu {
    border: none;
    background: transparent;
    .ant-menu-item {
      height: 28px;
      line-height: 28px;
      &.ant-menu-item-selected {
        border-radius: 4px;
        background: @primary-400;
      }
    }
  }
}

.ant-collapse {
  background: white !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 26px !important;
}
.ant-table-wrapper {
  &.table-xs {
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 20px !important;
    }
  }
}
.ant-select-arrow {
  top: 47% !important;
}
.company-images-container .ant-form .ant-form-item .ant-form-item-label label {
  width: unset;
}
.ant-form-item-label {
  font-weight: 600;
  text-align: left !important;
  color: #3d3d3d;
  margin-right: 8px;
  font-weight: bold;
}
.ant-form .ant-form-item .ant-form-item-label label {
  font-weight: 600;
  text-align: left;
  color: #3d3d3d;
  margin-right: 8px;
  font-weight: bold;
  // width: 170px; // poner este width contrae muchos labels, aplicar clase especial si es necesario
}
.modal-confirm-hide-footer .ant-modal-confirm-btns {
  display: none;
}

.ant-menu {
  &.transparent-menu {
    .ant-menu-item {
      display: flex;
      // justify-content: center;
      align-items: center;
      &.ant-menu-item-selected {
        background: transparent;
        color: #3f4c6b;
        font-weight: 700;
        /* .anticon {
        font-size: 26px !important;
      } */
      }
    }
  }
}

.ant-card {
  &.transparent-card {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
}

.modal-xl {
  .ant-modal {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .ant-modal-content {
      @media (min-width: 576px) {
        width: 90vw;
      }
    }
  }
}
.modal-lg {
  .ant-modal {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .ant-modal-content {
      @media (min-width: 576px) {
        width: 70vw;
      }
    }
  }
}
.modal-md {
  .ant-modal {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .ant-modal-content {
      @media (min-width: 576px) {
        width: 50vw;
      }
    }
  }
}
.user-form .ant-form-item-control-input-content {
  .ant-select,
  .ant-picker {
    /*width:250px !important;*/
    /*max-width:250px !important;*/
    width: 100% !important;
  }
}
.ant-tooltip-inner {
  border-radius: 4px !important;
  background: "#282c34";
}
.ant-collapse.ant-collapse-ghost {
  box-shadow: none;
}

.home-content-evaluacion-reportes-id {
  & .ant-collapse-header {
    display: none;
  }
  & .ant-collapse-content-box {
    padding: 0 !important;
  }
}
.ant-form-item-control-input-content textarea {
  outline: none !important;
  padding: 3px 6px;
  width: 100%;
}
.ant-input-number-input-wrap {
  background: white !important;
}

.soon .ant-menu-item.ant-menu-item-disabled:before,
.ant-menu-item.soon.ant-menu-item-disabled:before {
  content: "Pronto";
  background: #e1e048;
  color: black;
  border-radius: 4px;
  padding: 0px 5px;
  position: absolute;
  right: 0;
  height: 10px;
  line-height: 10px;
  font-size: 8px;
  right: 10px;
  font-variant: all-petite-caps;
}

.ant-menu-item {
  &:hover {
    background: @blue-100 !important;
  }
}
.ant-menu-item.ant-menu-item-selected:after {
  border-color: transparent !important;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ant-card-extra {
  padding: 0px !important;
}
.ant-menu.transparent-menu.ant-menu-root {
  .ant-menu-item {
    &.ant-menu-item-selected {
      background: @blue-100 !important;
    }
    border-radius: 8px !important;
    height: 32px;
    line-height: 32px;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    position: relative;
    &.admin-menu-item:before {
      content: "Alpha";
      background: @red-100;
      color: @red-700 !important;
      border-radius: 4px;
      padding: 0px 5px;
      position: absolute;
      height: 10px;
      line-height: 10px;
      font-size: 8px;
      right: 4px;
      top: 50%;
      transform: translate(-50%, -50%);
      font-variant: all-petite-caps;
    }
    &.admin-menu-item-beta:before {
      content: "Beta";
      background: @yellow-100;
      color: @yellow-700 !important;
      border-radius: 4px;
      padding: 0px 5px;
      position: absolute;
      height: 10px;
      line-height: 10px;
      font-size: 8px;
      right: 4px;
      top: 50%;
      transform: translate(-50%, -50%);
      font-variant: all-petite-caps;
    }
    /*&.manager-menu-item {*/
    /*&:before {*/
    /*content: '';*/
    /*background: @yellow-500;*/
    /*}*/
    /*}*/
  }
}
.ant-form-item-label {
  margin: 0px !important;
}
.ant-collapse-content {
  overflow: visible !important;
}

.ant-radio-group.ant-radio-group-outline {
  .ant-radio-button-wrapper:last-child {
    .ant-radio-button {
      border-radius: 0px 8px 8px 0px;
    }
    border-radius: 0px 8px 8px 0px;
  }
  .ant-radio-button-wrapper:first-child {
    .ant-radio-button {
      border-radius: 8px 0px 0px 8px;
    }
    border-radius: 8px 0px 0px 8px;
  }
  .ant-radio-button-wrapper-checked {
    border-color: #ccd2e1 !important;
    font-weight: bold;
    .ant-radio-button-checked {
      background: @primary-100;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: transparent;
  }
  .ant-radio-button-wrapper {
    height: 30px;
    line-height: 30px;
    color: @primary-500;
    border-color: #ccd2e1;
    font-size: 12px !important;
  }
}
.ant-breadcrumb {
  margin: 0px !important;
}

.ant-table-column-sorters {
  padding-bottom: 8px !important;
  padding-top: 8px !important;
}

.ant-table-row.ant-table-row-level-0:nth-child(even) {
  background: @table-th-bg;
  .ant-table-cell-fix-right,
  .ant-table-cell-fix-left {
    background: @table-th-bg;
  }
}

.ant-table-header tr th.ant-table-cell {
  color: #334857 !important;
  background: #fbfbfb !important;
  border-top: 0px;
  font-weight: bold !important;
  padding-bottom: 0px;
  padding-top: 0px;
}

.ant-table-cell.ant-table-column-sort {
  background: #fff9ee;
}
.ant-transfer-list {
  border: none !important;
}
.ant-transfer {
  .ant-transfer-list:first-child {
    .ant-transfer-list-content-item.ant-transfer-list-content-item-checked {
      &:hover {
        background: @green-100 !important;
      }
      background: @green-100 !important;
      .ant-checkbox-checked .ant-checkbox-inner {
        &:hover {
          border: none !important;
          background: @green-600 !important;
        }
        border: none !important;
        background: @green-700 !important;
      }
    }
  }
  .ant-transfer-list:last-child {
    .ant-transfer-list-content-item.ant-transfer-list-content-item-checked {
      &:hover {
        background: @red-100 !important;
      }
      background: @red-100 !important;
      .ant-checkbox-checked .ant-checkbox-inner {
        &:hover {
          border: none !important;
          background: @red-600 !important;
        }
        border: none !important;
        background: @red-700 !important;
      }
    }
  }
  .ant-transfer-operation {
    .ant-btn.ant-btn-primary {
      &:disabled {
        background: @gray-100 !important;
      }
      border: none !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      height: 50px;
      & > * {
        font-size: 20px !important;
      }
    }
    .ant-btn.ant-btn-primary:first-child {
      margin-bottom: 16px;
      background: @green-400;
    }
    .ant-btn.ant-btn-primary:last-child {
      background: @red-400;
    }
  }
}

.ant-menu-submenu.ant-menu.ant-menu-submenu-placement-rightTop {
  display: none;
}
.ant-select.ant-select-multiple .ant-select-selector {
  height: initial !important;
  .ant-select-selection-item {
    background: @teal-100 !important;
    padding: 0px 5px;
    border-radius: 4px;
  }
}
.ant-picker-content
  tbody
  tr
  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range
  .ant-picker-cell-inner,
.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-today.ant-picker-cell-selected::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: @blue-200 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: @secondary-500 !important;
}

.white-form {
  .ant-picker.ant-picker-small {
    background: #fdfdfd !important;
    border-radius: 6px !important;
    height: 36px;
    .ant-picker-input input {
      background: #fdfdfd !important;
    }
  }
  .ant-form-item .ant-input {
    height: 36px;
    padding-left: 10px;
    padding-right: 10px;
    background: #fdfdfd !important;
    border-radius: 6px !important;
  }
  .ant-form-item-control-input-content textarea {
    background: #fdfdfd !important;
    border-radius: 6px !important;
  }
}
