@import "../../../../app/Variables.less";

.hr-table.bordered.lineless-table .BaseTable__header-cell {
  border-color: transparent !important;
}
.hr-table {
  .BaseTable {
    box-shadow: none;
  }
  .BaseTable__table-main {
    outline: none;
  }
  /* header */
  .BaseTable__header {
    .BaseTable__header-cell-text {
      line-height: 1rem !important;
      font-size: 12px;
    }
  }
  /* header row */
  .BaseTable__header-row {
  }
  .row-selected .BaseTable__row-cell {
    background: #eee;
  }
  .BaseTable__header-cell:first-child {
    border-left: 1px solid #eee !important;
  }
  .BaseTable__row-cell:first-child {
    border-left: 1px solid #eee !important;
  }
  .BaseTable__header-cell:last-child {
    border-right: 1px solid #eee !important;
  }
  .BaseTable__row-cell:last-child {
    border-right: 1px solid #eee !important;
  }
  .BaseTable__row-cell {
    line-height: 13px;
  }
  /* header cell */
  &.bordered {
    .BaseTable__header-cell {
      border-right: 1px solid #eee !important;
      line-height: 13px;
    }
    .BaseTable__row-cell {
      border-right: 1px solid #eee !important;
      line-height: 13px;
    }
  }
  .BaseTable__header-cell {
    border-top: 1px solid #eee !important;
    background: @table-th-bg;
    color: @table-th-text;
  }
  /* data row */
  .BaseTable__row {
    border: none;
  }
  .BaseTable__row.highlight-row {
    .BaseTable__row-cell {
      background: @emerald-50 !important;
    }
  }
  /* data cell */
  .BaseTable__row-cell:first-child {
  }
  .BaseTable__row:nth-child(even) {
    .BaseTable__row-cell {
      background: @table-th-bg;
    }
  }
  .BaseTable__row-cell {
    background: @table-bg;
    border-bottom: 1px solid #eee !important;
  }
}

.hr-table.hr-table-light {
  .BaseTable {
    box-shadow: none;
  }
  .BaseTable__table-main {
    outline: none;
  }
  /* header */
  .BaseTable__header {
  }
  /* header row */
  .BaseTable__header-row {
    background: white !important;
  }
  .row-selected {
    .BaseTable__row-cell {
      background: #eee;
    }
  }
  .BaseTable__header-cell {
    color: #334857 !important;
    background: #fff !important;
    &:first-child {
      background: #fff !important;
      border-left: 1px solid #fff !important;
    }
    &:last-child {
      background: #fff !important;
      border-right: 1px solid #fff !important;
    }
  }
  .BaseTable__row-cell {
    border: 1px solid transparent !important;
    &:first-child {
      border-left: 1px solid #fff !important;
      border: 1px solid transparent !important;
    }
    &:last-child {
      border-right: 1px solid #fff !important;
      border: 1px solid transparent !important;
    }
  }

  .BaseTable__header-cell {
    border-top: 1px solid #fff !important;
    color: @table-th-text;
    background: transparent;
  }
  /* data row */
  .BaseTable__row-cell {
    background: transparent;
  }
  .BaseTable__row {
    border: none;
    background: #fff !important;
    background: @table-bg;
    border: 1px solid transparent !important;

    &:hover {
      /*border: 1px solid darken(@table-bg,15%) !important;*/
      border-radius: 2px;
      .BaseTable__row-cell {
        background: fade(@blue-100, 40%);
      }
    }
    &:nth-child(even) {
      &:hover {
        background: white !important;
      }
      background: darken(@table-bg, 1%);
    }
    .BaseTable__row-cell-text {
      line-height: 20px;
    }
    /*.BaseTable__row-cell*/
  }
}

@primary-color: #20384b;