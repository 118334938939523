@import "../../../../app/Variables.less";
@import "~antd/dist/antd.css";

/* Layout */
.ant-layout-sider {
  background: linear-gradient(180.46deg, #126d8c -3.63%, #263361 91.54%);
  height: 100vh;
  min-width: 260px !important;
  position: relative;
  left: 0;
  overflow-y: scroll;
  scrollbar-width: none;
}
.ant-layout-sider::-webkit-scrollbar {
  background: transparent;
  width: 0;
}
.menu hr {
  height: 60px;
  border: none;
}

.ant-ribbon-wrapper {
  margin-bottom: 1rem;
  > .ant-ribbon-sidebar {
    left: 52px;
    right: 60px !important;
    font-size: 0.9em;
    font-weight: 700;
    text-align: center;
    background-color: @emerald-600;
  }
}

.sidebar-company-name {
  &.sidebar-company-name-collapsed {
    font-size: 10px;
    padding-bottom: 10px;
  }
  padding-bottom: 30px;
  color: #fff !important;
  text-align: center;
}

/* Logo */
.sidebar-logo-container {
  height: 144px;
}
.menu .logo,
.company-logo {
  position: relative;
  left: 25px;
  width: 210px;
  padding: 30px 0;
  height: auto;
  margin-bottom: 0px;
  cursor: pointer;
}

/* Menus titles */

.ant-menu,
.ant-menu-sub {
  background: transparent;
  padding-left: 0;
  transition: all 0.3s;
}

/* Menus icons */
.submenu,
.sub-submenu {
  font-size: 12px;
  font-weight: 700;
}
.sub-submenu {
  font-size: 11px;
}
.submenu .anticon,
.sub-submenu .anticon {
  font-size: 20px;
  margin-right: 5px;
}
.layout-inline .sub-submenu .ant-menu-submenu-title:hover {
  padding-left: 52px !important;
  transition: all 0.3s;
}
.sub-submenu .ant-menu-sub .ant-menu-item {
  padding-left: 85px !important;
}
.ant-menu-item:hover.submenu {
  padding-left: 31px !important;
  transition: all 0.4s;
}
.ant-menu-submenu:hover.submenu {
  padding-left: 5px;
  transition: all 0.3s;
}
.ant-menu-item:hover,
.ant-menu-submenu-title,
.sub-submenu:hover,
.menuitem:hover,
.ant-menu-item-selected {
  background: transparent !important;
}

.ant-menu-submenu-open,
.ant-menu .ant-menu-sub {
  background: #126d8c !important;
}

.layout-inline .ant-menu-submenu-open,
.layout-inline .ant-menu .ant-menu-sub {
  border-bottom: 5px solid #126d8c;
}

/* Menus items */
.menuitem {
  font-weight: 500;
  text-transform: initial;
  padding-left: 60px !important;
}
/* Menus items hover and selected */
.menuitem .ant-menu-title-content {
  transition: all 0.3s;
}
.menuitem:hover .ant-menu-title-content {
  padding-left: 3px;
  transition: all 0.3s;
}
.submenu .ant-menu-submenu-arrow,
.submenu .ant-menu-item-icon,
.submenu .ant-menu-title-content {
  color: #fff !important;
}
.menuitem:hover .ant-menu-item-icon,
.menuitem:hover .ant-menu-title-content,
.menuitem:hover .ant-menu-submenu-arrow,
.ant-menu-item-selected.submenu .ant-menu-item-icon,
.ant-menu-item-selected.submenu .ant-menu-title-content,
.ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-title-content,
.ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-selected
  .ant-menu-submenu-selected
  .ant-menu-submenu-title
  .ant-menu-item-icon,
.ant-menu-submenu-selected
  .ant-menu-submenu-selected
  .ant-menu-submenu-title
  .ant-menu-title-content,
.ant-menu-submenu-selected
  .ant-menu-submenu-selected
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow,
.ant-menu-submenu-selected .ant-menu-item-selected .ant-menu-title-content {
  color: #06ffd2 !important;
}
.ant-menu-item-only-child {
  height: 20px !important;
}

/* Logout */
.sidebar-logout-item {
  position: fixed;
  width: 260px; // ???
  padding: 15px 20px;
  bottom: 0;
  color: #fff;
  cursor: pointer;
  background: #263361;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.sidebar-logout-item .icon {
  margin-right: 20px;
}

/* Minified styles */
.ant-menu-inline,
.ant-menu-vertical {
  border: none;
}
.layout-vertical.ant-layout-sider {
  flex: 0 0 52px !important;
  min-width: 52px !important;
  max-width: 52px !important;
  width: 52px !important;
}
.layout-vertical .menu .logo {
  left: 10px;
  padding: 40px 0 30px;
  width: 30px;
}
.ant-menu-vertical .submenu .ant-menu-submenu-arrow,
.ant-menu-vertical .submenu .ant-menu-title-content {
  display: none;
}
.ant-menu-submenu-vertical:hover {
  padding-left: 0 !important;
}
.ant-menu-vertical .ant-menu-item:hover {
  padding-left: 16px !important;
}
.ant-menu-submenu-vertical .anticon,
.ant-menu-vertical .submenu .anticon {
  margin-left: 0 !important;
  margin-right: 10px !important;
}
.ant-menu-submenu-popup .ant-menu-item-icon,
.ant-menu-submenu-popup .ant-menu-title-content,
.ant-menu-submenu-popup .ant-menu-submenu-arrow {
  color: #fff !important;
}
.ant-menu-submenu-popup {
  display: block !important;
}
.ant-menu-submenu-popup .menuitem {
  padding-left: 10px !important;
}
.ant-zoom-big-leave {
  display: none !important;
}
.ant-menu-vertical {
  padding: 0 !important;
}
.ant-menu-submenu-popup.ant-menu-submenu:hover {
  padding-left: 0 !important;
}
.ant-menu-submenu-popup .ant-menu-item-only-child {
  height: 30px !important;
  line-height: 30px !important;
}
.ant-menu-submenu-popup .ant-menu-item-selected .ant-menu-title-content {
  color: #06ffd2 !important;
}
.layout-vertical .ant-menu-item.submenu:hover {
  background: #126d8c !important;
}
.layout-vertical .sidebar-logout-item {
  width: 52px;
}
.layout-vertical .sidebar-logout-item .text {
  display: none;
}
.ant-menu-vertical .submenu .anticon {
  margin-right: 30px !important;
}
.ant-menu-vertical
  .ant-menu-submenu-active.sub-submenu
  > .ant-menu-submenu-title {
  padding-left: 21px !important;
}

/*************** ENTORNO DE DESARROLLO  *****************/

.ant-layout-sider.sider-dev {
  background: linear-gradient(180deg, #5e1f27 0%, #8b2a50 100%);
}

.submenu.sider-dev .ant-menu-sub,
.ant-layout-sider.sider-dev .ant-menu-submenu-open,
.ant-layout-sider.sider-dev .ant-menu .ant-menu-sub {
  background: #8b2a47 !important;
}

.ant-layout-sider.sider-dev .ant-menu-submenu-open,
.ant-layout-sider.sider-dev .ant-menu .ant-menu-sub {
  border-bottom: 5px solid #8b2a47 !important;
}

.ant-layout-sider.sider-dev .sidebar-logout-item {
  background: #8b2a50;
}

.ant-layout-sider.sider-dev .layout-vertical .ant-menu-item.submenu:hover {
  background: #8b2a47 !important;
}
.ant-layout-sider.sider-dev .menuitem:hover .ant-menu-item-icon,
.ant-layout-sider.sider-dev .menuitem:hover .ant-menu-title-content,
.ant-layout-sider.sider-dev .menuitem:hover .ant-menu-submenu-arrow,
.ant-layout-sider.sider-dev .ant-menu-item-selected.submenu .ant-menu-item-icon,
.ant-layout-sider.sider-dev
  .ant-menu-item-selected.submenu
  .ant-menu-title-content,
.ant-layout-sider.sider-dev
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title
  .ant-menu-item-icon,
.ant-layout-sider.sider-dev
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title
  .ant-menu-title-content,
.ant-layout-sider.sider-dev
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow,
.ant-layout-sider.sider-dev
  .ant-menu-submenu-selected
  .ant-menu-submenu-selected
  .ant-menu-submenu-title
  .ant-menu-item-icon,
.ant-layout-sider.sider-dev
  .ant-menu-submenu-selected
  .ant-menu-submenu-selected
  .ant-menu-submenu-title
  .ant-menu-title-content,
.ant-layout-sider.sider-dev
  .ant-menu-submenu-selected
  .ant-menu-submenu-selected
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow,
.ant-layout-sider.sider-dev
  .ant-menu-submenu-selected
  .ant-menu-item-selected
  .ant-menu-title-content {
  color: #fc5b9a !important;
}

@primary-color: #20384b;