.ant-transfer-list-header-none {
  .ant-transfer-list-header {
    display: none !important;
  }
}

.ant-table-thead {
  font-size: 0.813rem;
}

// .ant-table-small {
//   .ant-table-selection-column {
//     width: 12px;
//     min-width: 12px;

//     .ant-table-selection { ant-table-selection-column
//       width: 12px;
//       min-width: 12px;
//     }
//   }
// }

.ant-table-tbody {
  & > tr {
    & > td {
      font-size: 0.75rem !important;
    }
  }
}

@primary-color: #20384b;