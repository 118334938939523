.editable-attribute-value {
  position: relative;
  input {
    border-radius: 4px;
    border: none;
    background: rgba(242, 241, 238, 0.6);
  }
  &.no-padding .attribute-value.ant-dropdown-trigger {
    padding: 0px !important;
  }
}
.editable-cell-input-container.active {
  border-radius: 3px;
  background: white;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
  overflow: initial;
}
textarea.editable-cell-input {
  background: rgba(242, 241, 238, 0.6);
  border-radius: 3px;
  box-shadow: none !important;
  resize: none;
  padding: 0px 4px;
  min-height: 100%;
  border: none !important;
}
input.editable-cell-input {
  background: rgba(242, 241, 238, 0.6);
  box-shadow: none !important;
  border: none !important;
}
.menu-label {
  font-weight: bold;
}

@primary-color: #20384b;