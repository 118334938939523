.btn-group {
  span,
  div {
    // si tiene tooltip
    &:not(:first-child) {
      .big-button-container {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
    &:not(:last-child) {
      .big-button-container {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
    &:not(:last-child) {
      .big-button-container {
        border-right: none !important; /* Prevent double borders */
      }
    }
  }
  .big-button-container {
    // sin tooltip
    &:not(:first-child) {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    &:not(:last-child) {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    &:not(:last-child) {
      border-right: none !important; /* Prevent double borders */
    }
  }
}

@primary-color: #20384b;