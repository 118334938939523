.survey-app-cover-header {
  text-align: center;
  font-size: 60px;
  margin-top: 10px;
  font-weight: 900;
  margin-bottom: 10px;
}
.survey-app-cover-title {
  font-size: 25px;
  color: #333;
  line-height: 60px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 600;
  text-align: center;
}
.mobile .survey-app-cover-title {
  font-size: 22px;
  line-height: 30px;
}
.survey-app-cover-instructions {
  padding: 20px;
  padding-bottom: 0px;
  font-size: 14px;
  text-align: center;
}
.cover {
  position: absolute;
  top: 0px;
  left: 0;
  width: 60%;
  max-width: 300px;
}
.survey-app-cover {
  display: flex;
  flex-direction: column;
}
.mobile .survey-app-cover-instructions {
  padding: 0px 10px;
}
.mobile .survey-app-company-logo {
  margin-top: 20px;
  margin-bottom: 0px;
  width: 200px;
}
.ant-btn.survey-app-start-btn.ant-btn-primary {
  position: absolute;
  bottom: 00px;
  height: 40px;
  margin: 20px;
  width: calc(100% - 40px);
  font-size: 20px;
  border-radius: 4px;
}
.ant-btn.survey-app-start-btn.ant-btn-primary:hover {
  color: white;
  background: #3f4c6ba0;
}
.survey-cover-enter-btn-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.ant-btn.survey-cover-enter-btn {
  font-variant: all-small-caps;
  border-radius: 4px;
  padding: 0px 20px;
  background: #35c37e;
  border: #35c37e;
  height: 40px;
  font-size: 18px;
  margin: 0px;
}
.ant-btn.survey-cover-settings-btn {
  color: rgb(170, 170, 170);
  margin: 0px;
  padding: 0;
  padding-right: 10px;
  height: 10px;
}
.ant-card.floating-card.survey-cover-card h4 {
  display: inline;
  /* font-variant: petite-caps; */
  font-weight: 700;
  font-size: 18px;
  margin: 0px 16px 5px 0px;
  display: inline-block;
}
.ant-card.floating-card.survey-cover-card {
  /* border-left:6px solid #5150EA; */
  width: 100%;
  margin-bottom: 16px;
  position: relative;
}

.mobile .floating-card.survey-cover-card .ant-card-body {
  flex-direction: column;
}

.floating-card.survey-cover-card .ant-card-body {
  display: flex;
  padding: 12px;
}
.survey-cover-card-container {
  width: 100%;
  margin-bottom: 6px;
}
.survey-app-cover-logo-empresa,
.survey-app-cover-logo-empresa-9,
.survey-app-cover-logo-empresa-10 {
  width: 100%;
  height: auto;
  bottom: -60px;
  position: absolute;
}
