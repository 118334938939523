.tables-container .minimal-search-bar {
  margin-bottom: 16px;
}
.minimal-search-bar {
  display: flex;
  justify-content: space-between;
  box-shadow: none;
  border: none;
  background: transparent;
  box-shadow: none;
  .ant-input-affix-wrapper {
    padding: 0px 4px;
  }
  &.big {
    height: 42px;
  }
  &.small {
    height: 25px;
    > .ant-input-affix-wrapper {
      padding: 0px 4px;
    }
  }

  input:focus,
  input:active,
  input {
    padding: 4px 8px;
    border-radius: 4px;
    border-radius: 3px;
    /*background: rgba(55, 53, 47, 0.04);*/
    background: transparent;
    border: none;
  }

  .ant-input-affix-wrapper > input.ant-input {
    padding: 0px 4px;
    /*height: 26px;*/
  }
  .ant-input-affix-wrapper {
    box-shadow: none;
    border: none;
    background: transparent;
    box-shadow: none;
  }

  input:hover,
  input:focus,
  div:focus {
  }
  .ant-input-suffix,
  .ant-input-prefix {
    padding: 4px;
  }
}
.minimal-search-bar-filter:hover {
  background: rgba(55, 53, 47, 0.08);
}
.minimal-search-bar-filter {
  transition: all 0.2s ease-in-out;
  color: #bfbfbf;
  margin-left: 8px;
  line-height: 32px;
  padding: 0px 8px;
  border-radius: 4px;
  cursor: pointer;
}

@primary-color: #20384b;