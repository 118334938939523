@import "../../../../app/Variables.less";
.content-timeline {
  border-radius: 4px;
  background: #fff;
  padding: 0;
  margin: 0;
  position: relative;
  flex: unset;
}
.ant-menu-item.header-logout-item {
  right: 0px !important;
  position: absolute !important;
}
.logout-text {
  margin-right: 10px;
}

.home-content.home-content-cursos.home-content-campuschile {
  padding: 0px !important;
}

.main-layout {
  overflow: hidden; /**/
  width: 100%; /**/
  .layout-home {
    width: calc(100vw - 240px);
    // max-width: 1440px;
    @media (max-width: 740px) {
      width: 100% !important;
    }

    &.layout-home-collapsed {
      width: 100% !important;
    }
    .home-content-container {
      min-height: calc(100vh - 45px);
      height: calc(100vh - 45px);
      // overflow-y: scroll;
      // overflow-x: hidden !important;
    }

    max-height: 100vh;
    flex-grow: 1;
    margin: 0;
    .home-content {
      height: auto;
      /*padding: 16px 24px 36px 24px !important;*/
      padding: 24px 40px 36px !important;
      margin: 0;
      position: relative;
      flex: unset;
      /*background: #f6f6f6 !important;*/
      /*background: #f6f6f6;*/
      /*background: #fafafa;*/
      background: @body-bg !important;
      overflow: hidden !important;
      @media (max-width: 767px) {
        padding: 15px 3px 40px 5px !important;
      }
      .home-header {
        height: 43px;
        background: white;
        padding: 0;
      }
    }
  }
}

.mobile {
  .home-content {
    padding: 10px !important;
    height: 100%;

    .survey-app-container {
      height: 100%;
      .survey-app {
        height: 100% !important;
      }
    }
  }
}
.home-content-encuesta-id-evaluatee.home-content-hdv {
  position: relative;
}
.home-content-encuesta-id-evaluatee.home-content-hdv:before {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  opacity: 0.3;
  /*background-image: url(../components/HR/Surveys/Survey/SurveyApp/assets/hdv-survey-bg.png);*/
}

@primary-color: #20384b;