@import "../../../app/Variables.less";
.preview-upload-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  background: @dark-primary;
  position: relative;
  .left,
  .right,
  .center {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .left,
  .right {
    height: 250px;
    transition: all 0.3s ease;
    padding: 0 30px;
    background-color: rgba(0, 0, 0, 0.2);
    .anticon {
      font-size: 1.25rem;
      color: darken(#fff, 3%);
      transition: all 0.3s ease;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
      .anticon {
        color: #fff;
      }
    }
  }
  .center {
    img {
      width: 100%;
      // max-width: 150px;
      max-height: 250px;
      height: 250px;
      object-fit: cover;
    }
  }
  .preview-count {
    color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 5px;
  }
}

@primary-color: #20384b;