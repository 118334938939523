.steps-content {
  margin-top: 16px;
  padding: 20px 0;
}

.steps-action {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.ant-steps {
  .ant-steps-item {
    .ant-steps-item-title {
      font-weight: 600;
    }
  }
}

@primary-color: #20384b;