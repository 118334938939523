@import "../../../app/Variables.less";

.survey-evaluation-select {
  .ant-select-selector {
    background: white !important;
    border: 2px solid #bfbfbf !important;
    font-weight: 600;
    font-size: 1rem;
    height: 2rem !important;
  }
}

.black-bg {
  background: radial-gradient(black 15%, transparent 16%) 0 0,
    radial-gradient(black 15%, transparent 16%) 8px 8px,
    radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 0 1px,
    radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
  background-color: #282828;
  background-size: 16px 16px;
}
.ant-statistic.error {
  color: #ff7070;
}
.full-page {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.loading-survey {
  background: rgba(0, 0, 0, 0.1);
  height: 300px;
  width: 600px;
}
.survey-app-loading-bar {
  margin: auto;
  margin-top: 24px;
  border-radius: 5px;
  height: 36px;
  background: #ddd;
  width: 300px;
}

.survey-app-error-container,
.survey-app-loading-container {
  padding: 40px;
}

@media screen and (max-width: 600px) {
  .survey-app-loader-container,
  .ant-card.floating-card.survey-card {
    width: 100% !important;
  }
  .survey-app-loading-bar {
    width: 200px;
  }
  .survey-app-error-container,
  .survey-app-loading-container {
    padding: 20px;
  }
}

.survey-users-card {
  margin-bottom: 24px;
}
.survey-users-card .ant-card-body {
  padding: 20px;
  text-align: "center";
}
.survey-collapse {
  border: 0 !important;
  background: transparent !important;
}
.survey-collapse .ant-collapse-item {
  border: 0 !important;
}
.survey-collapse .ant-collapse-item .ant-collapse-header {
  padding: 0 !important;
  border: 0 !important;
}
.survey-collapse .ant-collapse-item .ant-collapse-content {
  background: transparent !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.survey-collapse .ant-collapse-item .ant-collapse-content-box {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.survey-question-number {
  background: #001529;
  border-radius: 50%;
  color: white;
  font-size: 12px;
  height: 25px;
  width: 25px;
  line-height: 25px;
  margin-left: -19px;
  margin-top: -35px;
  margin-right: 2px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
.question-summary-header {
  margin: 5px !important;
  padding-right: 20px;
}
.ant-collapse-item.question-summary-content {
  border: none !important;
}
.question-summary-answer {
}
.multiple-choice-number {
}

.ant-card.surveyItem .ant-card-body {
  padding: 14px;
}
.survey-step:hover .ant-steps-item-title {
  color: black !important;
}
.survey-step {
  cursor: pointer;
}
.survey-next-btn {
  margin-top: 16px;
}
.user-role {
  font-size: 12px;
  position: absolute;
  right: 12px;
  display: inline-block;
  border-radius: 5px;
  padding: 0 5px;
  color: white;
  /*font-variant:all-petite-caps;*/
}
.question-type {
  font-size: 12px;
  position: absolute;
  right: 66px;
  display: inline-block;
  border-radius: 5px;
  padding: 0 5px;
  color: white;
  /*font-variant:all-petite-caps;*/
}
.question-delete-btn,
.competence-delete-btn {
  font-size: 16px !important;
  position: absolute !important;
  top: 6px;
  right: 20px;
}
.competence-input:focus {
  border: none !important;
  box-shadow: none !important;
}
.competence-input {
  background: rgba(255, 255, 255, 0.6) !important;
  border: none !important;
  margin-bottom: 8px !important;
}
.competence-title {
  padding-right: 90px !important;
  height: 42px !important;
  font-size: 28px !important;
  line-height: 40px !important;
}
.competence-description {
  font-size: 20px !important;
  line-height: 30px !important;
}
.competences-questions {
  margin-left: 32px;
}
.add-question-btn {
  margin: 16px 0 0 50px;
  opacity: 0.7;
}
.ant-btn.toggle-new-question.selected {
  color: #0071cd;
  opacity: 1;
}
.ant-btn.toggle-new-question {
  opacity: 0.4;
  border: none;
  position: absolute;
  left: -50px;
  top: 0;
  transform: translate(0, 50%);
}
.competence-questions .ant-checkbox-wrapper {
  display: block;
}
.competence-questions .ant-checkbox {
  display: block;
  margin: auto;
  margin-top: 24px;
}
.competence-questions .ant-checkbox-inner {
  display: block;
  margin: auto;
}
.survey-admin-list {
  font-size: 14px;
}
.ant-btn.survey-create-btn:hover {
  color: white;
  background: rgba(0, 0, 0, 0.6);
}
.ant-btn.survey-create-btn {
  /*font-variant:all-petite-caps;*/
  display: block;
  border: none !important;
  height: 50px !important;
  line-height: 50px !important;
  font-size: 25px !important;
  background: rgba(0, 0, 0, 0.85);
  color: white;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.07);
  width: 300px;
  margin: auto;
  margin-top: 40px;
}
.survey-list-date {
  color: rgba(0, 0, 0, 0.65);
  font-size: 10px;
  margin: 5px 0;
}

.survey-step .ant-steps-item-title {
  font-size: 14px;
}
.survey-step .ant-steps-icon .anticon {
  font-size: 18px;
  vertical-align: baseline;
}

.ant-card-body .survey-type-tag {
  opacity: 0.6;
  border-radius: 2px;
  font-size: 10px;
}
.home-content-encuestas h2 {
  font-weight: bold;
}
.ant-typography code {
  border-color: transparent !important;
}
.survey-app-container-mobile .survey-app {
  min-height: calc(100vh - 100px);
}
.survey-app {
  min-height: 600px;
  background: white;
  border: none;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: auto;
  position: relative;
  overflow: visible;
}
.survey-app-progress {
  position: absolute;
  left: 50%;
  bottom: 5px;
  width: 50%;
  transform: translateX(-50%);
}
.survey-app-page-btn {
  position: absolute;
  bottom: 10px;
  transform: scale(1.2);
}
.survey-app-page-btn.next .ant-btn,
.survey-app-page-btn.prev .ant-btn {
  box-shadow: none;
  border: none;
  background: #e6a841;
  font-size: 12px;
  color: white;
  margin-bottom: 6px;
}
.survey-app-page-btn.next {
  right: 15px;
}
.survey-app-page-btn.prev {
  left: 15px;
}

.survey-app-question .survey-app-subtitle {
  margin-top: 10px;
  color: #777;
  font-size: 20px;
}
.survey-app-question {
  color: #555;
  display: flex;
  justify-content: center;
  padding: 10px 20px 0;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.survey-page {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.survey-page > div {
  height: 100%;
}
.survey-app-answer {
  padding: 10px 10px 30px;
  display: flex;
  justify-content: space-around;
}
.survey-app-answer .ant-radio-group {
  /*font-variant:small-caps !important;*/
}
.survey-app-send-btn .ant-btn {
  border-color: #34c47e;
  text-shadow: none;
  background-color: #34c47e;
}
.survey-app-end {
  height: calc(100% - 20px);
}
.survey-app-end-btn-container {
  text-align: center;
}
.survey-app-send-btn {
  transform: scale(2);
  top: 50%;
  margin-bottom: 30px;
}
.survey-app-reopen-btn {
  transform: scale(1.5);
}
.survey-app-end-title {
  /*font-variant:all-petite-caps;*/
  font-size: 22px;
  line-height: 30px;
  margin: 15px 0;
}
.survey-app-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.1;
  filter: saturate(60%);
}
.current-page-container {
  /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  color: #333;
  font-size: 16px;
  position: absolute;
  right: 5px;
  bottom: 0;
  letter-spacing: 3px;
  font-family: monospace;
}
.survey-app-company-logo {
  width: 300px;
  margin: auto;
  color: #3f4c6b;
  padding-bottom: 20px;
}

.survey-card {
  width: 400px;
  margin: auto;
  margin-top: 30px;
}
.survey-app-loader-container {
  margin: auto;
  width: 300px;
  text-align: center;
}
.loading-icon.primary {
  color: #3f4c6b; /*  0071cd */
}
.loading-icon.red {
  color: #ff7070;
}
.loading-icon.green {
  color: #70dd70;
}
.loading-icon {
  top: 10%;
  font-size: 144px;
}

.survey-app-answer > div {
  width: 100%;
}
.survey-app-end-btn {
  width: 280px !important;
}
.survey-app-answer .ant-radio-group {
  width: 100%;
}
.ant-input.multiple-open-with-choice-input {
  margin-right: 10px;
  height: 24px;
  margin-top: 4px;
}
h1.survey-app-cover-header {
  margin: 20px 0 0;
  font-size: 40px;
}
.ant-radio-group.ant-radio-group-outline.ant-radio-group-small
  .ant-radio-wrapper {
  margin: 0;
}
.ant-radio-group.ant-radio-group-outline.ant-radio-group-small span {
  font-size: 12px;
  padding: 0 0 0 5px;
}
.mobile h1.survey-app-cover-header {
  font-size: 25px;
}
.survey-app-container-mobile
  .survey-app-question.long
  .survey-app-question-title {
  font-size: 16px !important;
}
.mobile .survey-app-question {
  padding: 2px;
}
.mobile .survey-app-answer div {
  width: 100%;
}
.mobile .survey-app-answer {
  padding: 0 5px;
}
.mobile
  .ant-radio-group.ant-radio-group-outline.ant-radio-group-small
  .ant-radio-wrapper {
  display: flex;
  flex-direction: column;
}
.mobile
  .ant-radio-group.ant-radio-group-outline.ant-radio-group-small
  .ant-radio-wrapper
  span {
  font-size: 7px;
}
.fill-space {
  width: 100%;
  height: 100%;
}
.survey-app-answer-performance div {
  white-space: pre-wrap;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  flex-flow: row;
}
.mobile .survey-app-answer-performance div {
  word-break: break-word;
}
.survey-app-answer-performance .ant-radio-button-wrapper {
  border-radius: 50% !important;
  font-size: 18px;
  line-height: 36px;
  box-shadow: none;
  border: 1px solid white;
}
.survey-app-performance-btn label {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
  text-align: center;
}
.survey-app-performance-btn {
  width: 100%;
  margin: 5px 0;
}
.survey-app-answer-button label {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
  width: 100%;
  text-align: center;
}
.survey-app-answer-button {
  width: 100%;
  margin: 5px 0;
}
.face-img {
  width: 20px;
  height: 20px;
  filter: brightness(9);
  display: inline;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.face-img-container {
  z-index: 99;
  position: absolute;
  left: -8px;
  top: 4px;
  /* background:rgba(0,113,205,0.8); */
  background: #666;
  border-radius: 20px;
  padding: 5px;
  height: 30px;
  width: 30px !important;
}

.survey-app-progress .ant-progress-inner {
  height: 16px;
}
.survey-app-progress .ant-progress-bg {
  height: 16px !important;
}
.survey-app .survey-app-progress {
  position: absolute;
  right: 0;
  width: 100%;
  padding-right: 5px;
}
.survey-app-sent {
  text-align: center;
  font-size: 25px;
  line-height: 60px;
  margin-top: 20px;
}
.companyLogo {
  width: 30%;
  position: absolute;
  top: 10px;
  left: 10px;
  max-width: 160px;
}
.campusLogo {
  width: 50%;
  width: 30%;
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
}
.survey-app-crosshair {
  font-size: 16px;
  background: rgba(0, 21, 41, 0.8);
  color: white;
  border-radius: 5px;
  padding: 5px;
}

.survey-app-answer {
  display: flex;
  justify-content: center;
}
.open-input,
.multiple-open-input {
  min-width: 300px;
  margin-bottom: 8px !important;
}

.survey-app-progress {
  display: flex;
  justify-content: space-around;
  padding-bottom: 21px;
  width: calc(100% - 150px) !important;
}
.survey-app-progress-status-dot.current-page {
  transform: scale(1.2);
  background: #999;
  border: 1px solid #e7a841;
}
.survey-app-progress-status-dot {
  border: 1px solid #333;
  cursor: pointer;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}
.survey-app-progress-status-dot.incomplete {
  background: #ccc;
}
.survey-app-progress-status-dot.complete {
  background: #333 !important;
}

.survey-app-end-incomplete {
  display: flex;
  justify-content: center;
  height: calc(100vh - 100px);
  flex-direction: column;
}
.survey-app-select,
.survey-app-cascader {
  width: 100%;
}

.mobile .survey-app-question-title {
  padding: 10px;
}
.survey-app-question-title {
  font-size: 20px;
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 20px;
  margin-top: 10px;
}

.survey-app-competence {
  /*font-variant:all-small-caps;*/
  text-align: left;
  margin: auto;
}
.survey-app-competence-title {
  display: inline;
  font-size: 16px;
  color: #666;
  width: auto;
  border-bottom: 1px solid #eee;
  padding-bottom: 6px;
}
.survey-app-competence-more-btn {
  font-variant: normal;
  margin-left: 5px;
  font-size: 12px;
  color: #888;
  cursor: pointer;
}

.survey-app-container-mobile .survey-app-competence-description {
  font-size: 14px;
}
.survey-app-competence-description {
  margin-top: 5px;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 2px;
  text-align: justify;
}
.survey-app-answer .input-number {
  margin: 4px 0 0 4px;
  width: 50%;
}
.multiple-open-with-choice .options-container {
  display: flex;
}
.ant-select-selection {
  width: 100%;
}
.ant-radio-button.ant-radio-button-checked:active,
.ant-radio-button.ant-radio-button-checked:focus,
.ant-radio-button-wrapper:active,
.survey-app-performance-btn:active {
  outline: none;
}

.mobile .survey-app-performance-btn-text {
  font-size: 9px;
  left: -2px;
  width: calc(100% + 4px);
}
.survey-app-performance-btn-text {
  position: absolute;
  top: calc(100% + 6px);
  font-size: 12px;
  left: -8px;
  width: calc(100% + 16px);
  text-align: center;
}
.performance-instructions-row .survey-app-performance-btn-container {
  border: 0;
}
.survey-app-performance-btn-text,
.survey-app-performance-btn-container * {
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.survey-app-question-number {
  font-size: 0.8em;
  color: #888;
  letter-spacing: 0;
}
.survey-app-performance-btn-container {
  position: relative;
  padding-bottom: 6px;
  /*border-bottom:2px solid;*/
  margin: 0 2px;
}
.survey-app-performance-btn-container-1 {
  border-color: #ff5362 !important;
}
.survey-app-performance-btn-container-2 {
  border-color: #ff7e45 !important;
}
.survey-app-performance-btn-container-3 {
  border-color: #ffa020 !important;
}
.survey-app-performance-btn-container-4 {
  border-color: #ffc60c !important;
}
.survey-app-performance-btn-container-5 {
  border-color: #b2cb30 !important;
}
.survey-app-performance-btn-container-6 {
  border-color: #64d155 !important;
}
.survey-app-performance-btn-container-7 {
  border-color: #0acb73 !important;
}

.survey-app-performance-btn
  .ant-radio-button-wrapper-checked.performance-value-1 {
  background: #ff5362 !important;
  color: white;
}
.survey-app-performance-btn
  .ant-radio-button-wrapper-checked.performance-value-2 {
  background: #ff7e45 !important;
  color: white;
}
.survey-app-performance-btn
  .ant-radio-button-wrapper-checked.performance-value-3 {
  background: #ffa020 !important;
  color: white;
}
.survey-app-performance-btn
  .ant-radio-button-wrapper-checked.performance-value-4 {
  background: #ffc60c !important;
  color: white;
}
.survey-app-performance-btn
  .ant-radio-button-wrapper-checked.performance-value-5 {
  background: #b2cb30 !important;
  color: white;
}
.survey-app-performance-btn
  .ant-radio-button-wrapper-checked.performance-value-6 {
  background: #64d155 !important;
  color: white;
}
.survey-app-performance-btn
  .ant-radio-button-wrapper-checked.performance-value-7 {
  background: #0acb73 !important;
  color: white;
}

.survey-app-performance-btn .performance-value-1 {
  color: #ff5362;
  border-color: #ff5362 !important;
}
.survey-app-performance-btn .performance-value-2 {
  color: #ff7e45;
  border-color: #ff7e45 !important;
}
.survey-app-performance-btn .performance-value-3 {
  color: #ffa020;
  border-color: #ffa020 !important;
}
.survey-app-performance-btn .performance-value-4 {
  color: #ffc60c;
  border-color: #ffc60c !important;
}
.survey-app-performance-btn .performance-value-5 {
  color: #b2cb30;
  border-color: #b2cb30 !important;
}
.survey-app-performance-btn .performance-value-6 {
  color: #64d155;
  border-color: #64d155 !important;
}
.survey-app-performance-btn .performance-value-7 {
  color: #0acb73;
  border-color: #0acb73 !important;
}

.survey-app-performance-btn .performance-value-1:hover {
  background: #ff5362 !important;
  color: white;
}
.survey-app-performance-btn .performance-value-2:hover {
  background: #ff7e45 !important;
  color: white;
}
.survey-app-performance-btn .performance-value-3:hover {
  background: #ffa020 !important;
  color: white;
}
.survey-app-performance-btn .performance-value-4:hover {
  background: #ffc60c !important;
  color: white;
}
.survey-app-performance-btn .performance-value-5:hover {
  background: #b2cb30 !important;
  color: white;
}
.survey-app-performance-btn .performance-value-6:hover {
  background: #64d155 !important;
  color: white;
}
.survey-app-performance-btn .performance-value-7:hover {
  background: #0acb73 !important;
  color: white;
}

/* INSTRUCTIONS VALUE */
.instructions-performance-value {
  font-size: 21px;
  line-height: 35px;
  text-align: center;
  width: 35px;
  height: 35px;
  color: white;
  border-radius: 50%;
}
.instructions-performance-value-1 {
  background: #ff5362 !important;
}
.instructions-performance-value-2 {
  background: #ff7e45 !important;
}
.instructions-performance-value-3 {
  background: #ffa020 !important;
}
.instructions-performance-value-4 {
  background: #ffc60c !important;
}
.instructions-performance-value-5 {
  background: #b2cb30 !important;
}
.instructions-performance-value-6 {
  background: #64d155 !important;
}
.instructions-performance-value-7 {
  background: #0acb73 !important;
}

/* INSTRUCTIONS TEXT */
.performance-instructions-text-1 {
  background-color: #ff536230 !important;
}
.performance-instructions-text-2 {
  background-color: #ff7e4530 !important;
}
.performance-instructions-text-3 {
  background-color: #ffa02030 !important;
}
.performance-instructions-text-4 {
  background-color: #ffc60c30 !important;
}
.performance-instructions-text-5 {
  background-color: #b2cb3030 !important;
}
.performance-instructions-text-6 {
  background-color: #64d15530 !important;
}
.performance-instructions-text-7 {
  background-color: #0acb7330 !important;
}

.ant-progress.survey-app-progress-bar {
  bottom: 24px;
  width: 62%;
  left: 50%;
  transform: translateX(-48%);
}
.survey-app-menu-container {
  z-index: 99;
  position: absolute;
  top: 2px;
  right: 2px;
}

.survey-app-menu-container
  .ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  background: rgba(0, 21, 41, 0.9);
}
.survey-app-menu-container
  .ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical
  li:hover {
  background: #3e4c6b;
}
.survey-app-menu-container
  .ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical
  * {
  color: white !important;
}

.performance-instructions-text {
  line-height: 35px;
  font-size: 15px;
  width: 100%;
  height: 35px;
  padding-left: 35px;
  margin-left: -25px;
  border-radius: 0 50px 50px 0;
}

.survey-app-instructions.performance-instructions {
  margin: auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /*height: calc(100vh - 360px);*/
}
.mobile .survey-app-instructions.performance-instructions {
  height: calc(100vh - 280px);
}
.mobile .survey-instructions-img {
  width: 173px;
}
.survey-instructions-img {
  width: 203px;
  margin: auto;
  display: block;
}
.survey-app-container {
  height: 100%;
}
.mobile .survey-app-container {
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
}

.performance-instructions-row {
  display: flex;
}
.ant-btn-primary.survey-app-instructions-btn {
  position: absolute;
  bottom: 0;
  height: 35px;
  margin: 20px;
  width: calc(100% - 35px);
  font-size: 20px;
}
.mobile .instructions-title {
  font-size: 14px;
}
.instructions-title {
  display: block;
  width: 80%;
  margin: 10px auto;
  padding: 0;
  font-weight: bold;
  font-size: 16px;
}
.survey-app-container-mobile .instructions-header {
  font-size: 18px;
  line-height: 20px;
}
.instructions-header {
  font-weight: 700;
  text-align: center;
  font-size: 25px;
  /* font-variant:all-petite-caps; */
  margin-top: 10px;
}
.survey-page-container {
  min-height: calc(100vh - 100px);
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 66px;
}
.survey-page-container {
  padding-bottom: 0;
}

.survey-app-evaluatee .evaluatee-header {
  /*background: #001529;*/
  background: @blue-900;
  color: white;
  padding: 2px;
  text-align: center;
  font-size: 16px;
}
.survey-app-evaluatee .evaluatee-main {
  padding: 10px;
  background: #fafafa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 0;
}

.mobile .survey-app-evaluatee .evaluatee-avatar {
  background: #82b74a;
  color: white;
  text-align: center;
  border-radius: 50%;
  margin-right: 20px;

  font-size: 25px;
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
  line-height: 50px;
}
.survey-app-evaluatee .evaluatee-avatar {
  background: #82b74a;
  color: white;
  text-align: center;
  border-radius: 50%;
  margin-right: 20px;

  font-size: 40px;
  min-width: 90px;
  min-height: 90px;
  width: 90px;
  height: 90px;
  line-height: 90px;
  position: relative;
  overflow: hidden;
}
.evaluatee-avatar-initials {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.survey-app-evaluatee .evaluatee-avatar:after {
  content: "";
  position: absolute;
  opacity: 0.1;
  left: -2px;
  bottom: -10%;
  width: 100%;
  height: 100%;
  transform: scale(0.9);
  /*background-image:url(Survey/SurveyApp/assets/avatar-shadow.png);*/
}

.mobile .survey-app-evaluatee .evaluatee-name {
  margin-right: 35px;
  font-size: 18px;
}
.survey-app-evaluatee .evaluatee-name {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #16502c;
}
.survey-app-evaluatee .evaluatee-logo img {
  margin-left: 10px;
  width: 80px !important;
  height: auto;
  transform: translateY(-10px);
}
.mobile .survey-app-evaluatee .evaluatee-logo img {
  width: 40px !important;
  position: absolute;
  right: 0;
}
.survey-app-evaluatee .evaluatee-detail-container {
  display: flex;
}
.survey-app-evaluatee .evaluatee-divider {
  width: 1px;
  height: 25px;
  background: #ddd;
  margin: 8px 10px 0;
}
.mobile .survey-app-evaluatee .evaluatee-data,
.mobile .survey-app-evaluatee .evaluatee-label {
  font-size: 10px;
}
.survey-app-evaluatee .evaluatee-label {
  font-size: 12px;
  color: #888;
}
.survey-app-evaluatee .evaluatee-data {
  font-size: 12px;
  font-weight: bold;
  color: #777;
}
.mobile .survey-app-container {
  width: 100%;
  transform: translateX(-10px);
}
.page-animation-container {
  flex-grow: 1;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.survey-app-answer.survey-app-answer-performance {
  padding-bottom: 57px;
}
.survey-app-instructions-container {
  background-image: url(Survey/SurveyApp/assets/survey-footer.png);
  background-position-x: 0;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}
.survey-app-instructions-thumbs-up {
  width: 40px;
}
.mobile .survey-app-instructions-thumbs-up {
  width: 40px;
}
.survey-app-instructions-divider {
  border-bottom: 2px solid #eee;
  margin: 20px;
  height: 1px;
  width: 100%;
}
.survey-app-instructions-subheader {
  display: flex;
  margin-bottom: 10px;
}
.survey-app-sent-text {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.survey-app-sent-subtext {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.survey-app-sent-img {
  display: block;
  margin: 20px auto;
  max-width: 340px;
}
.survey-app-end-img {
  max-width: 360px;
}
.mobile .survey-app-sent-img,
.mobile .survey-app-end-img {
  max-width: 280px;
}

.survey-success-btn {
  text-align: center;
  padding: 10px;
}
.survey-success-btn .ant-btn {
  transform: scale(1.2);
  border-color: #34c47e;
  /*font-variant:all-small-caps;*/
  text-shadow: none;
  background-color: #34c47e;
  color: white;
}
.survey-app-intro-background {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.survey-app-intro-survey {
  max-width: 340px;
  position: absolute;
  bottom: -28px;
  left: 50%;
  transform: translateX(-50%);
}
.survey-app-answer-comment {
  border-radius: 4px;
  margin: 30px 30px 10px;
  width: calc(100% - 60px);
}

.survey-reports {
  .ant-card .ant-card-head {
    border-radius: 0px;
    /*background:rgba(255,0,0,0.08);*/
    min-height: 40px;
    height: 40px;
    /*line-height:40px;*/
    padding: 5px 0px;
    padding-left: 10px;
    margin: 0px;
    /*align-items:center;*/
    /*display:flex;*/
    /*justify-content:space-between;*/
    .ant-card-head-title {
      font-size: 16px;
      padding: 0px;
    }
    .ant-card-head-title:first-child {
      line-height: 32px;
      color: #444;
    }
  }
}
.survey-report-chart-type-selector {
  .ant-select {
    min-width: 170px;
    .ant-select-selector {
      background: white !important;
      box-shadow: none !important;
    }
  }
}

.my-evaluations {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
}
.to-evaluate {
  background: #21b781 !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  width: 126px !important;
}

.to-continue-evaluate {
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  width: 126px !important;
}

.my-evaluation-user {
  color: #3d3d3d !important;
  font-size: 14px !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.evaluation-summary {
  color: #3090df !important;
}

.card-evaluation {
  height: 311px !important;
  .icon-summary {
    font-size: 32.14px !important;
  }
  .icon-summary-title {
    font-size: 17.72px !important;
  }
}

.bg-survey-progress {
  height: 100%;
  width: 100%;
  opacity: 0.35;
  position: absolute;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-image: url("./Images/surveyProgress.svg");
}

.bg-survey-ok {
  height: 100%;
  width: 100%;
  opacity: 0.35;
  position: absolute;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-image: url("./Images/Ok-pana.svg");
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  border: 0;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  background-color: @gray-100 !important;
  border-radius: 10px 10px 0px 0px !important;
  &-active {
    background-color: #b0ce67 !important;
  }
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  border: 0;
}

.survey-tag {
  &-blue {
    color: #1e1dc4;
    background: #a091fa63;
    border-color: #6755fd00;
  }
  &-purple {
    color: #d226e9;
    background: #c649c94a;
    border-color: #6755fd00;
  }
}

@primary-color: #20384b;