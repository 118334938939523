@import "../../../app/Variables.less";

.ant-pagination {
  @media (max-width: 575px) {
    width: 100%;
    padding-bottom: 3px !important;
    text-align: center;
  }
  @media (min-width: 576px) {
    display: flex;
    align-items: center;
    justify-content: center !important;
  }

  li {
    font-size: 0.85rem;
    font-weight: 600;

    &:not(:nth-of-type(1)) {
      margin: 0 4px;
    }

    &:nth-of-type(1) {
      margin: 0;
    }

    &.ant-pagination-item {
      border-color: @gray-200;
      a {
        color: @primary-500;
      }
      &:hover {
        border-color: @secondary-500;
        a {
          color: @secondary-500;
        }
      }
    }

    &.ant-pagination-item-active {
      border-color: @secondary-500;
      a {
        color: @secondary-500;
      }
    }
  }

  .ant-pagination-item-link {
    width: 100%;
    &:enabled {
      color: @primary-500;
    }

    &:not(a) {
      line-height: 0;
      border-color: white;

      &:hover {
        &:enabled {
          color: @secondary-500;
          border-color: @secondary-500;
        }
      }
    }
  }

  .ant-pagination-options {
    @media (max-width: 324px) {
      padding-top: 8px;
    }
    @media (max-width: 576px) {
      display: inline-block !important;
    }
  }

  .ant-pagination-total-text {
    width: 100%;
    margin-right: 15px !important;

    @media (max-width: 575px) {
      margin-right: 5px !important;
      padding-bottom: 3px !important;
    }
  }
}

.table-xs {
  .ant-pagination {
    li {
      height: 30px;
      font-size: 0.75rem;
    }
  }
}

@primary-color: #20384b;