.hr-card {
  background-color: white;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.07);
  padding: 1.5rem 1rem 1rem 1.5rem;
  &.card-padding-12 {
    padding: 12px !important;
  }
  & .hr-card-title {
    font-family: Rubik !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.hr-card-title {
  font-family: Rubik !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

@primary-color: #20384b;