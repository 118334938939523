@import "../../../app/Variables.less";
@primary-btn: lighten(@primary-600, 5%);
.ant-card
  .ant-card-head
  .ant-card-head-wrapper
  .ant-card-extra
  .big-button-icon {
  font-size: 20px;
}
.big-button-container {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.09);
  &.ghost {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    &:hover {
      color: darken(@primary-btn, 10%);
      background: @blue-100 !important;
    }
    &.dangerOutline {
      &:hover,
      &:focus {
        background: @danger-color !important;
        color: white !important;
      }
    }
  }
  &.big {
    height: 42px;
    padding: 0px 14px;
    font-size: 16px;
    .anticon {
      font-size: 20px !important;
    }
  }
  &.small {
    height: 22px;
    font-size: 12px;
    padding: 0px 6px;
    .anticon {
      font-size: 10px !important;
    }
  }
  &.extraSmall {
    height: 20px;
    font-size: 10px;
    padding: 0px 4px;
    .anticon {
      font-size: 8px !important;
    }
  }
  &.sidebar {
    background: transparent;
    border: transparent;
    box-shadow: none;
    display: flex;
    font-weight: unset;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-bottom: 4px;
    text-align: left;
    letter-spacing: 0.025em;
    &.selected,
    &:active,
    &:focus,
    &:hover {
      box-shadow: none;
      background: transparent;
      font-weight: bold;
    }
    .big-button-icon {
      margin-right: 6px;
    }
  }
  &.light {
    background: #fff;
    color: @primary-btn;
    border: 1px solid darken(#fff, 10%);
    &:hover,
    &:focus {
      background: darken(#fff, 3%);
    }
  }
  &.info {
    background: @secondary-500;
    color: white;
    &:hover,
    &:focus {
      background: fade(@secondary-500, 90%);
      color: white;
    }
  }
  &.warning {
    background: @warning-color;
    color: white;
    &:hover,
    &:focus {
      background: fade(@warning-color, 90%);
      color: white;
    }
  }
  &.success {
    background: @save-color;
    border-width: 1px;
    color: white;
    border-color: @save-color;
    &:focus {
      @bg: fade(@save-color, 50%);
      border-color: @bg;
      background: @bg;
      color: white;
    }
    &:hover {
      @bg: fade(@save-color, 70%);
      border-color: @bg;
      background: @bg;
      color: white;
    }
  }
  &.successOutline {
    background: #fff;
    color: @save-color;
    border: 1px solid @save-color;
    &:hover,
    &:focus {
      opacity: 0.9;
      color: @save-color;
    }
  }
  &.danger {
    background: @danger-color;
    color: white;
    &:hover,
    &:focus {
      background: fade(@danger-color, 90%);
      color: white;
    }
  }
  &.dangerOutline {
    background: #fff;
    color: @danger-color;
    border: 1px solid @danger-color;
    &:hover,
    &:focus {
      background: @danger-color;
      opacity: 0.9;
      color: white;
    }
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.blue {
    background: @blue-700;
    color: white;
    &:hover,
    &:focus {
      color: white;
      background: fade(@blue-700, 85%);
    }
  }
  &.purple {
    color: @purple-500;
    &:focus {
      color: @purple-500;
    }
    &.light {
      color: @purple-300;
      &:focus {
        color: @purple-300;
      }
    }
  }
  &.primary {
    background: @primary-btn;
    color: white;
    .light {
      background: #6679a3;
    }
    &:hover,
    &:focus {
      color: white;
      background: fade(rgba(51, 72, 87, 1), 85%);
    }
  }
  &.primaryOutline {
    background: #fff;
    color: @primary-btn;
    border: 1px solid @primary-btn;
    &:hover,
    &:focus {
      background: @primary-btn;
      opacity: 0.9;
      color: white;
    }
  }
  &.secondary {
    background: @secondary-500;
    color: white;
    &:hover,
    &:focus {
      background: fade(@secondary-500, 95%);
      opacity: 0.9;
      color: white;
    }
    &.secondaryOutline {
      background: #fff;
      color: @secondary-500;
      border: 1px solid @secondary-500;
      &:hover,
      &:focus {
        background: @secondary-500;
        opacity: 0.9;
        color: white;
      }
    }
  }
  cursor: pointer;
  height: 28px;
  width: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: 14px;
  /*text-transform: uppercase;*/
  white-space: nowrap;
  user-select: none;
  font-weight: bold;
  color: rgb(61, 88, 102);
  border: 1px solid lighten(rgb(61, 88, 102), 50%);
  padding: 0px 14px;
  outline: none;
  border-radius: 4px;
  transition: background 140ms ease-out 0s, color 140ms ease-out 0s,
    opacity 140ms ease-out 0s;
  background: rgba(143, 166, 178, 0.2);

  &:hover,
  &:focus {
    color: rgb(71, 102, 118);
    background: rgba(143, 166, 178, 0.5);
  }
  &:active {
    box-shadow: rgba(15, 15, 15, 0.05) 0px 3px 6px inset;
    background: rgba(204, 217, 223, 0.7);
    transition: none 0s ease 0s;
  }
  &.iconButton {
    height: auto;
    width: auto;
    border-radius: 50%;
    padding: 6px;
    &.small {
      padding: 8px;
      .anticon {
        font-size: 12px;
      }
    }
    &.extraSmall {
      padding: 6px;
      .anticon {
        font-size: 12px;
      }
    }
    .anticon {
      font-size: 16px;
    }
  }
  &.borderless {
    border: none;
    box-shadow: none;
  }
  &.rounded {
    border-radius: 50%;
  }
}

@primary-color: #20384b;