@import "../../../app/Variables.less";
@cal-radius: 1000px;
.ant-picker-date-panel {
  .ant-picker-content {
    th {
      font-weight: bold !important;
    }
  }
}

.cal-cell-selected {
  background: @blue-400;
  color: white;
  .cal-cell-holiday {
    border-radius: 0px;
    margin: 0;
    background: fade(@red-500, 80%);
    color: white;
  }
  &.cal-cell-selected-start {
    font-weight: bold;
    background: @blue-600;
    border-radius: @cal-radius 0px 0px @cal-radius;
  }
  &.cal-cell-selected-end {
    font-weight: bold;
    background: @blue-600;
    border-radius: 0px @cal-radius @cal-radius 0px;
    &.cal-cell-selected-start {
      margin: 0px 15px;
      border-radius: @cal-radius @cal-radius @cal-radius @cal-radius;
    }
  }
}
.cal-cell-wrapper {
  padding-top: 2px;
  padding-bottom: 2px;
}
.ant-picker-cell-in-view {
  .cal-cell-wrapper {
    color: #3f3c3c;
    font-weight: 600;
  }
}
.cal-cell-app-unselected {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.cal-cell-app-selected {
  background: fade(lighten(@blue-600, 20%), 10%);
  border-color: lighten(@blue-600, 10%);
  border-width: 1px;
  border-left: 0px;
  border-right: 0px;
  &.cal-cell-app-selected-start {
    border-radius: @cal-radius 0px 0px @cal-radius;
  }
  &.cal-cell-app-selected-end {
    border-radius: 0px @cal-radius @cal-radius 0px;
    &.cal-cell-app-selected-start {
      border-radius: @cal-radius @cal-radius @cal-radius @cal-radius;
    }
  }
}
.cal-cell-holiday {
  border-radius: 2px;
  border: 1px solid rgb(79, 70, 229);
  background-color: rgba(79, 70, 229, 0.05);
  color: rgb(79, 70, 229);
  font-weight: bold;
  margin: 0 17px;
}
.cal-cell-weekend {
  background: fade(@emerald-600, 15%);
}
.ant-picker-cell::before {
  pointer-events: none;
}
.ant-picker-cell {
  & > div {
    height: 24px;
    line-height: 24px;
  }
}

.ant-picker-calendar .ant-picker-panel {
  border: 0;
}

@primary-color: #20384b;