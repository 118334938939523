@import "~antd/dist/antd.css";
@import "~react-perfect-scrollbar/dist/css/styles.css";
@import "./Variables.less";
@import "./styles/ant-design.less";
@import "animate.css";

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7fafc;
  height: 100%;
}
.h-full-vh {
  height: 100vh;
}
.bg-body {
  background: #f7fafc;
}
* {
  font-family: "Open Sans", sans-serif !important;
}
.font-rubik {
  font-family: Rubik !important;
}
* .font-mono {
  font-family: "B612", "Courier Prime", Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace !important;
}
.font-mono {
  input {
    font-family: "B612", "Courier Prime", Menlo, Monaco, Consolas,
      "Liberation Mono", "Courier New", monospace !important;
  }
  font-family: "B612", "Courier Prime", Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace !important;
}
html {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.App.offline {
  filter: grayscale(0.8);
  position: absolute;
  height: 100%;
  width: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: @dark-primary;
  color: @dark-primary-text;
  min-height: 100vh;
  .flex-center();
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: @link;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.editor-statusbar {
  display: none;
}
.rotate-on-hover {
  box-shadow: 10px 0 20px rgba(0, 0, 0, 0.19), 6px 0 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rotate-on-hover:hover {
  transform: rotate(90deg);
}
.wiggle-on-hover:hover {
  animation: wrench 3.5s ease infinite;
}

@keyframes wrench {
  0% {
    -webkit-transform: rotate(-9deg);
    transform: rotate(-9deg);
  }
  8% {
    -webkit-transform: rotate(9deg);
    transform: rotate(9deg);
  }
  10% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  18% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  20% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  28% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  30% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  38% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  40% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  48% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  50% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  58% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  60% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  68% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  100%,
  75% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/* ContentLoader (componente usado como placeholder mientras se realiza el request */
*[aria-label="Loading interface..."] {
  border-radius: 6px;
}
.fast {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}

#campushr input.attribute-input:hover,
#campushr input.attribute-input:focus {
  border: none !important;
  box-shadow: none !important;
}
#campushr .attribute-value input:focus,
#campushr .attribute-value input:hover,
#campushr input:hover,
#campushr input.attribute-input:hover,
#campushr input.attribute-input:focus,
#campushr input:focus {
  outline: none !important;
  -webkit-appearance: none !important;
}
.inline {
  display: inline;
}
.inline.minimal-clickable {
  padding: 4px;
  text-decoration: underline;
}
.minimal-clickable.dashed {
  border: 1px dashed @button-hover-bg;
}
.minimal-clickable {
  text-align: center;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  animation: 0.2s ease-in-out background;
}
.minimal-clickable:hover {
  background: @button-hover-bg;
  cursor: pointer;
}
.padding-4 {
  padding: 4px !important;
}
.particles {
  position: absolute;
  width: 100vw;
  height: 100vh;
}
.margin-bottom-8 {
  margin-bottom: 8px !important;
}
.margin-bottom-16 {
  margin-bottom: 16px !important;
}
.no-vertical-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.table-responsive {
  display: block !important;
  width: 100% !important;
  overflow-x: auto !important;
  -webkit-overflow-scrolling: touch !important;
  overflow-y: hidden !important;
}
@media (max-width: 1100px) {
  .table-responsive-xl {
    display: block !important;
    width: 100% !important;
    overflow-x: scroll !important;
    -webkit-overflow-scrolling: touch !important;
  }
}
@media (max-width: 991px) {
  .table-responsive-lg {
    display: block !important;
    width: 100% !important;
    overflow-x: scroll !important;
    -webkit-overflow-scrolling: touch !important;
  }
}
@media (max-width: 768px) {
  .table-responsive-md {
    display: block !important;
    width: 100% !important;
    overflow-x: scroll !important;
    -webkit-overflow-scrolling: touch !important;
  }
}
#dropdown-container .menu-label,
.minimal-dropdown .menu-label {
  font-variant: all-petite-caps;
  text-align: left !important;
  color: @label-color;
  margin-left: 10px;
}
.minimal-toolbar {
  position: absolute;
  top: 8px;
  right: 10px;
  .flex-end();
}

.flex {
  display: flex;
}
.relative {
  position: relative;
}
.card-tabs {
  overflow: hidden;
}

th.table-column-left,
td.table-column-left {
  text-align: left !important;
  & div {
    text-align: left !important;
    justify-content: flex-start !important;
    align-items: center !important;
  }
}
th.table-column-right,
td.table-column-right {
  text-align: right !important;
  & div {
    text-align: right !important;
    justify-content: flex-end !important;
    align-items: center !important;
  }
}

th.table-column-center,
td.table-column-center {
  text-align: center !important;
  & div {
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

.editable-tag {
  cursor: pointer !important;
  padding: 0 !important;
  display: flex;
  .anticon {
    display: inline-block;
    margin-left: 5px;
  }
}

/* Truncate long text */
.truncate-container {
  display: flex;
  .truncate-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 110px;
    overflow: hidden;
    @media (max-width: 991px) {
      width: 100px;
    }
    @media (max-width: 768px) {
      width: 80px;
    }
  }
}

.toolbar {
  position: absolute;
  top: 7px;
  right: 8px;
  .flex-center();
  &.vacations {
    top: 10px;
    right: 10px;
  }
  &.calendar {
    top: 10px;
    right: 130px;
  }
}

.btn-group {
  div {
    // si tiene tooltip
    &:not(:first-child) {
      .big-button-container {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
    &:not(:last-child) {
      .big-button-container {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
    &:not(:last-child) {
      .big-button-container {
        border-right: none !important; /* Prevent double borders */
      }
    }
  }
  .big-button-container {
    // sin tooltip
    &:not(:first-child) {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    &:not(:last-child) {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    &:not(:last-child) {
      border-right: none !important; /* Prevent double borders */
    }
  }
}

.btn-action-status {
  width: 60px;
  height: 25px !important;
  font-weight: 600 !important;
  font-size: 0.75rem !important;
  padding: 0 !important;
  letter-spacing: 0.2px;
  &.active {
    background-color: @success-color !important;
    border-color: @success-color !important;
    color: #fff !important;
    &:hover {
      background-color: darken(@success-color, 10%) !important;
      border-color: darken(@success-color, 10%) !important;
    }
  }

  &.inactive {
    background-color: @danger-color !important;
    border-color: @danger-color !important;
    color: #fff !important;
    &:hover {
      background-color: darken(@danger-color, 15%) !important;
      border-color: darken(@danger-color, 15%) !important;
    }
  }
}

.helper-text {
  font-size: 0.75rem;
  font-weight: 600;
}

.img-group-container {
  margin: auto;
  outline: 0;
  position: relative;
  .flex-center();

  .img-group {
    .flex-center();
    .img-item {
      border: 1px solid #fff;
      margin-left: -8px;
      width: 50px;
      height: 50px;
      .flex-center();
      overflow: hidden;
      position: relative;
      flex-shrink: 0;
      border-radius: 50%;
      transition: all 0.3s ease;
      box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);

      img.main-img {
        color: transparent;
        width: 100%;
        height: 100%;
        object-fit: cover;
        text-align: center;
        text-indent: 10000px;
        background-size: contain;
        background-position: center;
        object-fit: cover;
        cursor: pointer;
      }
    }
  }
}

.ril__outer {
  background-color: rgba(0, 0, 0, 0.3) !important;
}
.ReactVirtualized__Grid.ReactVirtualized__List {
  width: auto !important;
}
.flex-header {
  display: flex;
  justify-content: space-between;
  h3 {
    display: inline-block;
  }
}

.d-none {
  display: none !important;
}
.d-flex {
  display: flex !important;
}
.d-block {
  display: block !important;
}

.w-100,
.d-full-width {
  width: 100% !important;
}

.bg-hr-teal {
  background: #094157;
}
.bg-hr-teal-300 {
  background: lighten(#094157, 5%);
}
.attribute-key {
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
  color: rgba(55, 53, 47, 0.6);
  font-weight: 500;
}
.text-11,
.text-xs {
  font-size: 11px;
}
.arial {
  font-family: "arial" !important;
}

button:focus {
  outline: none !important;
}
.dot-container {
  width: 100%;
  display: flex;
  align-items: center;
  @keyframes pulse-danger {
    0% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 fade(@danger-color, 70%);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 6px fade(@danger-color, 0%);
    }

    100% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 fade(@danger-color, 0%);
    }
  }
  @keyframes pulse-pending {
    0% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 fade(@warning-400, 70%);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 6px fade(@warning-400, 0%);
    }

    100% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 fade(@warning-400, 0%);
    }
  }
  @keyframes pulse-success {
    0% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 fade(@success-color, 70%);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 6px fade(@success-color, 0%);
    }

    100% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 fade(@success-color, 0%);
    }
  }
  .dot-item {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    margin-right: 5px;
    &.current {
      background: @success-color;
      animation: pulse-success 2s infinite;
    }
    &.archived {
      background: @danger-color;
      animation: pulse-danger 2s infinite;
    }
    &.pending {
      background: @warning-400;
      animation: pulse-pending 2s infinite;
    }
    &.green,
    &.success {
      background: @success-color;
      &.pulse {
        animation: pulse-success 2s infinite;
      }
    }
    &.yellow,
    &.neutral {
      background: @warning-400;
      &.pulse {
        animation: pulse-pending 2s infinite;
      }
    }
    &.big {
      width: 16px;
      height: 16px;
    }
  }
}

.companies-search-bar {
  & div {
    background: white !important;
    opacity: 1;
  }
}

.user-item-box {
  overflow: hidden;
}
.user-item-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-item-subtext {
  font-size: 11px;
  color: #666;
}

.markdown-editor-container > div {
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  .ql-container.ql-snow {
    background: #f0f0f0;
  }
  .ql-toolbar.ql-snow,
  .ql-container.ql-snow {
    border: none;
  }
}

.login-bg-img {
  transition: all ease-in-out 0.4s;
  &.loading {
    filter: blur(2px);
  }
}

.hr-data-table {
  thead th {
    padding: 2px 5px !important;
    font-weight: bold;
    min-width: 120px;
    border-right: 1px solid #eee;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    &:first-child {
      border-left: 1px solid #eee;
    }
    &:last-child {
      border-right: 1px solid #eee;
    }
  }
  thead th {
    background: @table-th-bg;
    color: @table-th-text;
  }
  tbody td {
    background: @table-bg;
  }
  tbody td {
    padding: 2px 5px !important;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    &:first-child {
      border-left: 1px solid #eee;
    }
    &:last-child {
      border-right: 1px solid #eee;
    }
  }
  table {
    width: 100%;
    border-spacing: 0;
  }

  display: inline-block;
  position: relative;
  border-spacing: 0;
  display: block;
  max-width: 100%;
  padding: 0 0 15px;
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }
}
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: transparent !important;
  opacity: 0.9;
}
.useritem-item-popover {
  .attribute {
    font-size: 10px;
    line-height: 12px;
  }
}
.ant-picker-calendar-header {
  display: none !important;
}

.payroll-button-blue {
  background: #1890ff !important;
  border-color: #1890ff !important;
}

.payroll-alert-info {
  width: 100%;
}
.ant-card .ant-card-head .ant-card-head-wrapper .ant-card-extra {
  margin-right: 0px;
}
.ant-popover {
  &.custom-delete-popover {
    .ant-btn-primary {
      background-color: @red-500 !important;
      border-color: @red-500 !important;
    }
  }
}
.ant-slider {
  &.custom-slider {
    .ant-slider-track {
      background-color: @secondary-600;
    }
    .ant-slider-handle {
      background-color: @secondary-600;
      border-color: @secondary-600;
    }
  }
}

.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel {
  .ant-picker-cell-disabled {
    .ant-picker-cell-inner {
      color: rgba(0, 0, 0, 0.25) !important;
      background: #f5f5f5;
    }
  }
}

.custom-select-search {
  .ant-select-selector {
    height: 38px !important;
    padding: 5px 11px !important;
  }
  .ant-avatar.ant-avatar-circle {
    width: 25px !important;
    height: 25px !important;
  }

  .user-item-subtext {
    display: none !important;
  }
}

.label-full {
  label {
    width: 100% !important;
  }
}

.ant-card .ant-card-head .ant-card-head-wrapper {
  flex-direction: row;
}
.ant-breadcrumb {
  margin-bottom: 1.25rem;
}
.text-bold {
  font-weight: bold;
}
.force-font-mono {
  & * {
    font-family: monospace !important;
  }
}
.admin-collapse .ant-collapse-header {
  color: @red-400 !important;
}
.admin-card .ant-card-head-title {
  color: @red-400 !important;
}

.title-header-card {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  color: @primary-500;
  font-size: 24px;
  line-height: 18px;
}

.carousel .slide img {
  width: auto !important;
}

.title-page-screen {
  font-family: Rubik !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.indicator-title {
  font-family: Rubik !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
}

.title-page-screen-big {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
}

.title-page-screen-normal {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
}

.description-card {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: right;
  color: @secondary-600;
}

.description-card-payroll {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  color: @secondary-600;
}

.divider {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.divider-header {
  border-bottom: 2px dashed #ccd2e1;
}

.flow {
  .ant-collapse-header {
    display: flow-root !important;
    background-color: #b1ce6745;
    border-color: #1e1dc4;
    border: 2px;
  }
}

.title-dashboard {
  color: @primary-500;
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 18px;
}

.font-general {
  font-family: Rubik !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #1890ff !important;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #1890ff !important;
}

.previred-image {
  background-image: url("../components/HR/Payrolls/Icons/previred.png");
  background-repeat: no-repeat;
  background-size: 115px auto;
  background-position: right bottom;
}

.highcharts-credits {
  display: none !important;
}

.ant-table-thead {
  .ant-table-cell {
    background: #ffffff;
  }
}

.ant-table-cell {
  background: #ffffff;
}

.survey-tag {
  &-blue {
    color: #1e1dc4;
    background: #a091fa63;
    border-color: #6755fd00;
  }
  &-purple {
    color: #d226e9;
    background: #c649c94a;
    border-color: #6755fd00;
  }
}

.bg-survey-performance {
  height: 100%;
  width: 100%;
  opacity: 0.25;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../components/HR/Images/performance.svg");
}

.bg-survey-performance-best-competence {
  height: 100%;
  width: 100%;
  opacity: 0.25;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center right;
  background-image: url("../components/HR/Images/performance-best.svg");
}

.bg-survey-performance-bad-competence {
  height: 100%;
  width: 100%;
  opacity: 0.25;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center right;
  background-image: url("../components/HR/Images/performance-bad.svg");
}
.control-doc-masive-docs {
  background: #4a4a99 !important;
}

.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
    color: black !important;
    background: #b1ce67 !important;
    border-color: #b1ce67 !important;
    width: 80px !important;
    height: 30px !important;
    display: flex !important;
    cursor: pointer !important;
    align-content: center !important;
    flex-wrap: nowrap !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 20px !important;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
    background: #1ab8d90f !important;
    border-color: rgba(0, 0, 0, 0.03) !important;
}

.css-qc6sy-singleValue {
    color: hsl(0, 0%, 20%);
    grid-area: 1/1/2/3;
    margin-left: 4px !important;
    margin-right: 2px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
}
@primary-color: #20384b;