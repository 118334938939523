@import "../../../app/Variables.less";

.trigger-container {
  position: absolute;
  top: 0px;
  left: 0%;
  height: inherit;
  padding: 0;
  .trigger {
    .flex-center();
    height: inherit;
    background-color: transparent;
    border: none;
    font-size: 18px;
    padding: 0 15px;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease;
    color: #fff;
    color: #333;
    &:hover {
      background-color: lighten(rgba(0, 0, 0, 0.2), 50%);
      // color: darken(#fff, 15%);
    }
  }
}

@primary-color: #20384b;