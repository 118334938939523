@import "../../../../app/Variables.less";

.ant-layout-header {
  position: relative;
  box-shadow: @shadow-navbar !important;
  height: 40px;
  width: 100%;
  padding: 0 30px;
  margin: 0px;
  width: 100%;
  background: #fff;
  color: @dark-primary-text;
  color: #333;
  z-index: 9;
  display: flex;
  align-items: center;
  .navbar-right {
    height: inherit;
    .flex-end();
  }
  .navbar-left {
    height: inherit;
    .flex-start();
    .current-page {
      font-weight: 600;
      margin-left: 25px;
      font-size: 18px;
    }
  }
}
.layout-home.layout-home-collapsed {
  .logo-navbar {
    .company-logo {
      width: auto;
      padding: 5px;
      display: block;
      width: auto;
      height: 42px;
      margin-left: 16px;
    }
  }
}

.navbar-profile {
  cursor: pointer;

  .ant-dropdown-trigger {
    padding: 0 !important;
  }
}
.profile-dropdown {
  width: 250px;
  @media (max-width: 740px) {
    width: 100%;
  }
  .ant-dropdown-menu {
    margin-top: 8px;
    border-radius: 6px;
    .navbar-profile-info {
      .flex-center();
      flex-direction: column;
      padding: 10px 15px;
      .navbar-profile-main {
        .flex-center();
        flex-direction: row;
        .avatar {
          width: 50% !important;
          .user-avatar {
            margin-bottom: 10px;
            form {
              .avatar {
                .user-avatar {
                  .ant-avatar {
                    width: 80px !important;
                    height: 80px !important;
                    margin-right: 10px !important;
                    .flex-center();
                    span {
                      font-size: 1.5rem;
                    }
                  }
                  .anticon {
                    display: none !important;
                  }
                }
              }
            }
          }
        }
        .info {
          .flex-start();
          flex-direction: column;
          .user-name {
            color: #202124;
            font-weight: 600;
          }
        }
      }
    }
    .ant-divider {
      margin: 5px 0 10px;
    }
    .ant-dropdown-menu-item {
      margin-bottom: 5px;
      color: #091e31;
      .anticon {
        font-size: 16px;
      }
    }
  }
}

#navbar-breadcrumbs .ant-breadcrumb {
  width: 400px;
  & .ant-breadcrumb-link a {
    color: #3f4c6b !important;
  }
}

@primary-color: #20384b;