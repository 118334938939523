@import "../../../app/Variables.less";
.images-slider-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 250px;
  width: 100%;
  background: @dark-primary;
  position: relative;
  .expand {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
    cursor: pointer;
    .anticon {
      transition: all 0.3s ease;
      font-size: 1.5rem;
      color: #fff;
    }
    @media (min-width: 759px) {
      &:hover {
        .anticon {
          font-size: 1.75rem;
        }
      }
    }
  }
  .left,
  .right,
  .center {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .left,
  .right {
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 70px 20px;
    background-color: rgba(0, 0, 0, 0.2);
    .anticon {
      font-size: 1.25rem;
      color: darken(#fff, 3%);
      transition: all 0.3s ease;
    }
    &:hover {
      // padding: 70px 30px;
      background-color: rgba(0, 0, 0, 0.4);
      .anticon {
        color: #fff;
      }
    }
  }
  .center {
    position: relative;
    img {
      width: 100%;
      // max-width: 150px;
      max-height: 100%;
      height: 100%;
      object-fit: cover;
      // cursor: url('./lens.png'), auto !important;
      // cursor: -moz-zoom-in;
      // cursor: -webkit-zoom-in;
      // cursor: zoom-in;
    }
    // .expand {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   .anticon {
    //     font-size: 2rem;
    //     color: #fff;
    //   }
    // }
  }
  .preview-count {
    z-index: 9;
    color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 3px 8px;
    background: rgba(0, 0, 0, 0.6);
  }
}

@primary-color: #20384b;