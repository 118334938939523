.handle {
  flex: none;
  width: 20px;
  height: 100%;
  cursor: pointer;
  &::before {
    content: "";
    border-left: 4px dotted #ccc;
    display: block;
    height: 20px;
    margin: 15px 3px;
  }
  &:hover::before {
    border-color: #888;
  }
}

@primary-color: #20384b;