.content-timeline {
  border-radius: 4px;
  background: #fff;
  padding: 0;
  margin: 0;
  position: relative;
  flex: unset;
}
.home-content {
  /* height:calc(100vh - 43px); */
  height: 100vh;
  background: #f6f6f6;
  overflow: scroll;
}
.layout-home .home-header {
  height: 43px;
  background: white;
  padding: 0;
}
.ant-menu-item.header-logout-item {
  right: 0px !important;
  position: absolute !important;
}
.logout-text {
  margin-right: 10px;
}
.black-bg {
  background: radial-gradient(black 15%, transparent 16%) 0 0,
    radial-gradient(black 15%, transparent 16%) 8px 8px,
    radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 0 1px,
    radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px !important;
  background-color: #282828 !important;
  background-size: 16px 16px !important;
}
.full-height {
  height: 100vh;
}
