@import "../../../app/Variables.less";

.avatar > div {
  margin-bottom: 0px !important;
  .ant-avatar-string {
    &.avatar-initials-small {
      font-size: 0.475rem !important;
    }
    font-size: 0.875rem !important;
    transform: scale(1) translateX(-50%) translateY(-50%) !important;
    top: 50%;
  }
}
.avatar-dropdown-caret {
  display: flex;
  align-items: center;
  margin-left: 3px;
  font-size: 0.75rem;
}
.default-avatar-image {
  /*filter: brightness(0) opacity(0.1);*/
  /*margin-top: 8%;*/
  /*transform: scale(0.9);*/
  background-image: url(./avatar-circles.png);
  background-size: cover;
  background-position-y: -1px;
  /*background-blend-mode: soft-light;*/
}
.user-avatar-container.shadowed .ant-avatar {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
}
.user-avatar {
  position: relative;

  .user-avatar-border {
    border: 2px solid @emerald-500;
  }
  .user-avatar-online {
    color: @emerald-500;
    position: absolute;
    right: -0.9rem;
    top: -0.5rem;
    font-size: 1.35rem;
  }
}

@primary-color: #20384b;