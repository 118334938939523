@import "../../../app/Variables.less";

.info-widget {
  &.ghost {
    box-shadow: none !important;
  }
  word-wrap: break-word;
  min-height: 6rem;
  &.border {
    border: 1px solid #f0f0f0;
  }
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.07);
  .content {
    .main-info {
      @media (min-width: 1440px) {
        font-size: 1.875rem;
      }
    }
  }
}

.global-image {
  background-image: url("../../HR/Surveys/Evaluations/Icons/global.png");
  background-repeat: no-repeat;
  background-size: 115px auto;
  background-position: right bottom;
}

.members-image {
  background-image: url("../../HR/Surveys/Evaluations/Icons/members.png");
  background-repeat: no-repeat;
  background-size: 115px auto;
  background-position: right bottom;
}

.performance-image {
  background-image: url("../../HR/Surveys/Evaluations/Icons/performance.png");
  background-repeat: no-repeat;
  background-size: 115px auto;
  background-position: right bottom;
}

.progress-image {
  background-image: url("../../HR/Surveys/Evaluations/Icons/progress.png");
  background-repeat: no-repeat;
  background-size: 115px auto;
  background-position: right bottom;
}

.questions-image {
  background-image: url("../../HR/Surveys/Evaluations/Icons/questions.png");
  background-repeat: no-repeat;
  background-size: 115px auto;
  background-position: right bottom;
}

.respondents-image {
  background-image: url("../../HR/Surveys/Evaluations/Icons/respondents.png");
  background-repeat: no-repeat;
  background-size: 115px auto;
  background-position: right bottom;
}

@primary-color: #20384b;