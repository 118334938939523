.status-dropdown-menu.ant-dropdown {
  z-index: 999;
  background: transparent;
  width: inherit;
  height: 100%;
  padding: 0 !important;
  .ant-dropdown-menu {
    padding: 8px 10px !important;
    .menu-label {
      font-weight: 600;
    }
    li.ant-dropdown-menu-item {
      margin: 0 !important;
      padding: 0 !important;
      list-style: none !important;
      margin-bottom: 8px !important;
      &:nth-last-child(1) {
        margin-bottom: 0px !important;
      }
      .ant-tag.inner-tag {
        width: 100%;
        cursor: pointer;
      }
    }
  }
}
.ant-dropdown-trigger {
  // padding: 2px 8px !important;
  cursor: pointer;
}

@primary-color: #20384b;