@import "../../../app/Variables.less";

.survey-reports {
  .info-card {
    .anticon {
      font-size: 20px !important;
      text-align: center;
      line-height: unset;
    }
  }
}

.info-card {
  &.ghost {
    box-shadow: none !important;
  }
  word-wrap: break-word;
  &.border {
    border: 1px solid #f0f0f0;
  }
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.07);
  .content {
    .info-icon {
      position: absolute;
      opacity: 0.05;
      .anticon {
        font-size: 80px !important;
        overflow: unset;
        text-align: center;
        line-height: 40px;
      }
      &.warning .anticon {
        color: @warning-500;
      }
      &.danger .anticon {
        color: @red-500;
      }
      &.info {
        color: @blue-600;
      }
      &.success {
        color: @green-500;
      }
      &.violet {
        background: #e14eca;
        background-image: linear-gradient(
          to bottom left,
          #e14eca,
          #ba54f5,
          #e14eca
        );
        background-size: 210% 210%;
        background-position: 100% 0;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
          0 7px 10px -5px rgba(225, 78, 202, 0.4);
      }
    }
    .main-info {
      @media (min-width: 1440px) {
        font-size: 1.875rem;
      }
    }
  }
}

@primary-color: #20384b;