@import "../../../../app/Variables.less";
.hr-tag {
  &.hr-tag-default {
    background: #eee !important;
    color: #777 !important;
  }
  &.hr-tag-payroll_cost_center {
    background: @blue-100 !important;
    color: @blue-800 !important;
  }
  &.hr-tag-formula_code {
    background: @blue-100 !important;
    color: @blue-800 !important;
  }
  &.hr-tag-contract_code {
    background: @blue-100 !important;
    color: @blue-800 !important;
  }
  &.hr-tag-enforce {
    &.hr-tag-2-admin {
      background: @red-100 !important;
      color: @red-800 !important;
    }
  }
  &.hr-tag-misc {
    &.hr-tag-2-soon {
      background: @purple-100 !important;
      color: @purple-800 !important;
    }
  }
  &.hr-tag-document_author {
    &.hr-tag-2-externo {
      background: @purple-100 !important;
      color: @purple-800 !important;
    }
    &.hr-tag-2-sistema {
      background: @emerald-200 !important;
      color: @emerald-600 !important;
    }
    &.hr-tag-2-usuario {
      color: @indigo-700 !important;
      background: @indigo-100 !important;
    }
  }
  &.hr-tag-user_survey_status {
    &.hr-tag-2-active {
      color: @yellow-700 !important;
      background: @yellow-200 !important;
    }
    &.hr-tag-2-inprogress {
      background: @indigo-200 !important;
      color: @indigo-600 !important;
    }
    &.hr-tag-2-complete {
      background: @emerald-200 !important;
      color: @emerald-800 !important;
    }
  }
  &.hr-tag-expense_report_status {
    &.hr-tag-2-closed {
      background: @emerald-200 !important;
      color: @emerald-600 !important;
    }
    &.hr-tag-2-pending {
      background: @indigo-700 !important;
      color: @indigo-100 !important;
    }
    &.hr-tag-2-draft {
      color: @yellow-700 !important;
      background: @yellow-200 !important;
    }
  }
  &.hr-tag-survey_extra {
    &.hr-tag-2-autoevaluation {
      background: @pink-200 !important;
      color: @pink-600 !important;
    }
    &.hr-tag-2-score {
      background: @primary-color !important;
      color: white !important;
    }
  }
  &.hr-tag-survey_type {
    &.hr-tag-2-performance {
      background: @indigo-200 !important;
      color: @indigo-600 !important;
    }
    &.hr-tag-2-climate {
      background: @blue-100 !important;
      color: @blue-700 !important;
    }
  }
  &.hr-tag-survey_status {
    &.hr-tag-2-draft {
      color: @yellow-700 !important;
      background: @yellow-200 !important;
    }
    &.hr-tag-2-pending {
      background: @indigo-200 !important;
      color: @indigo-600 !important;
    }
    &.hr-tag-2-complete {
      background: @blue-100 !important;
      color: @blue-700 !important;
    }
    &.hr-tag-2-paused {
      background: @indigo-700 !important;
      color: @indigo-100 !important;
    }
    &.hr-tag-2-suspended {
      background: @emerald-200 !important;
      color: @emerald-600 !important;
    }
    &.hr-tag-2-active {
      background: @emerald-200 !important;
      color: @emerald-600 !important;
    }
  }
  &.hr-tag-expense_status {
    &.hr-tag-2-reported {
      background: @purple-200 !important;
      color: @purple-600 !important;
    }
    &.hr-tag-2-adding_to_report {
      background: @indigo-200 !important;
      color: @indigo-600 !important;
    }
    &.hr-tag-2-rejected {
      background: @red-100 !important;
      color: @red-600 !important;
    }
    &.hr-tag-2-replaced {
      background: @indigo-700 !important;
      color: @indigo-100 !important;
    }
    &.hr-tag-2-cancelled {
      background: @red-100 !important;
      color: @red-600 !important;
    }
    &.hr-tag-2-active {
      background: @emerald-200 !important;
      color: @emerald-600 !important;
    }
    &.hr-tag-2-approved {
      background: @emerald-200 !important;
      color: @emerald-600 !important;
    }
    &.hr-tag-2-draft {
      color: @yellow-700 !important;
      background: @yellow-200 !important;
    }
  }
  &.hr-tag-milestone_type {
    &.hr-tag-2-user_created {
      color: @yellow-700 !important;
      background: @yellow-200 !important;
    }
    &.hr-tag-2-user_change {
      background: @emerald-200 !important;
      color: @emerald-600 !important;
    }
    &.hr-tag-2-percentage_change {
      background: @emerald-200 !important;
      color: @emerald-600 !important;
    }
    &.hr-tag-2-supervisor_change {
      background: @indigo-700 !important;
      color: @indigo-100 !important;
    }
    &.hr-tag-2-supervisor_approval {
      background: @blue-100 !important;
      color: @blue-700 !important;
    }
  }
  &.hr-tag-action_plans_status {
    &.hr-tag-2-ready {
      background: @emerald-200 !important;
      color: @emerald-600 !important;
    }
    &.hr-tag-2-in_progress {
      color: @yellow-700 !important;
      background: @yellow-200 !important;
    }
    &.hr-tag-2-no_plans {
      background: @red-100 !important;
      color: @red-600 !important;
    }
  }
  &.hr-tag-audit_type {
    &.hr-tag-2-created {
      background: @emerald-200 !important;
      color: @emerald-600 !important;
    }
    &.hr-tag-2-session {
      background: @purple-100 !important;
      color: @purple-500 !important;
    }
    &.hr-tag-2-updated {
      background: @yellow-200 !important;
      color: @yellow-700 !important;
    }
    &.hr-tag-2-process {
      background: @gray-100 !important;
      color: @gray-600 !important;
    }
    &.hr-tag-2-deleted {
      background: @red-100 !important;
      color: @red-500 !important;
    }
  }
  &.hr-tag-gender {
    &.hr-tag-2-m {
      background: @blue-100 !important;
      color: @blue-700 !important;
    }
    &.hr-tag-2-f {
      background: @pink-100 !important;
      color: @pink-600 !important;
    }
  }
  &.hr-tag-action_plan_approval_status {
    &.hr-tag-2-approved {
      background: @emerald-200 !important;
      color: @emerald-600 !important;
    }
    &.hr-tag-2-pending {
      color: @yellow-700 !important;
      background: @yellow-200 !important;
    }
    &.hr-tag-2-rejected {
      color: @red-500 !important;
      background: @red-100 !important;
    }
  }
  &.hr-tag-payroll_summaries_status {
    &.hr-tag-2-paid {
      background: @emerald-200 !important;
      color: @emerald-600 !important;
    }
    &.hr-tag-2-in_progress {
      color: @yellow-700 !important;
      background: @yellow-200 !important;
    }
    &.hr-tag-2-unpaid {
      color: @purple-700 !important;
      background: @purple-200 !important;
    }
  }
  &.hr-tag-holiday_type {
    &.hr-tag-2-civil {
      color: @purple-700 !important;
      background: @purple-200 !important;
    }
    &.hr-tag-2-religious {
      color: @emerald-600 !important;
      background: @emerald-200 !important;
    }
  }
  &.hr-tag-importation_status {
    &.hr-tag-2-in_queue {
      color: @yellow-700 !important;
      background: @yellow-200 !important;
    }
    &.hr-tag-2-in_progress {
      color: @yellow-700 !important;
      background: @yellow-200 !important;
    }
    &.hr-tag-2-wait_for_aproval {
      background: @indigo-100 !important;
      color: @indigo-500 !important;
    }
    &.hr-tag-2-error {
      color: @red-800 !important;
      background: @red-300 !important;
    }
    &.hr-tag-2-canceled {
      color: @red-500 !important;
      background: @red-100 !important;
    }
    &.hr-tag-2-error {
      color: @red-500 !important;
      background: @red-100 !important;
    }
    &.hr-tag-2-completed {
      color: @emerald-600 !important;
      background: @emerald-200 !important;
    }
  }
  &.hr-tag-payroll_summary_type {
    &.hr-tag-2-payroll {
      background: @teal-100 !important;
      color: @teal-600 !important;
    }
    &.hr-tag-2-reprocess_diff {
      color: @pink-500 !important;
      background: @pink-100 !important;
    }
    &.hr-tag-2-reprocess {
      color: @purple-500 !important;
      background: @purple-100 !important;
    }
    &.hr-tag-2-advance {
      color: @emerald-600 !important;
      background: @emerald-100 !important;
    }
    &.hr-tag-2-settlement {
      background: @primary-200 !important;
      color: @primary-600 !important;
    }
  }
  &.hr-tag-dashboard_result {
    &.hr-tag-2-payroll {
      background: @indigo-300 !important;
      color: @indigo-700 !important;
    }
    &.hr-tag-2-additional {
      color: @yellow-700 !important;
      background: @yellow-200 !important;
    }
    &.hr-tag-2-advance {
      color: @emerald-600 !important;
      background: @emerald-100 !important;
    }
    &.hr-tag-2-severances {
      color: @red-500 !important;
      background: @red-100 !important;
    }
    &.hr-tag-2-transfer {
      color: @emerald-600 !important;
      background: @emerald-200 !important;
    }
    &.hr-tag-2-payroll_transfer {
      color: @pink-800 !important;
      background: @pink-200 !important;
    }
    &.hr-tag-2-salary_book {
      color: @teal-600 !important;
      background: @teal-100 !important;
    }
    &.hr-tag-2-month_end_closing {
      background: @indigo-100 !important;
      color: @indigo-500 !important;
    }
    &.hr-tag-2-advance_end_closing {
      color: @emerald-600 !important;
      background: @emerald-100 !important;
    }
    &.hr-tag-2-previred {
      background: @purple-100 !important;
      color: @purple-500 !important;
    }
  }
  &.hr-tag-payroll_activity_principal {
    &.hr-tag-2-principal {
      color: @teal-600 !important;
      background: @teal-100 !important;
    }
    &.hr-tag-2-especial {
      color: @pink-800 !important;
      background: @pink-200 !important;
    }
  }
  &.hr-tag-payroll_activity_open {
    &.hr-tag-2-open {
      color: @emerald-600 !important;
      background: @emerald-100 !important;
    }
    &.hr-tag-2-closed {
      background: @indigo-100 !important;
      color: @indigo-600 !important;
    }
  }
  &.hr-tag-payroll_dashboard_activity {
    &.hr-tag-2-ddjj1887 {
      background: @yellow-100 !important;
      color: @yellow-600 !important;
    }
    &.hr-tag-2-centralization {
      background: @green-100 !important;
      color: @green-600 !important;
    }
    &.hr-tag-2-centralization_full {
      background: @green-100 !important;
      color: @green-600 !important;
    }
    &.hr-tag-2-payroll_pdf {
      background: @pink-100 !important;
      color: @pink-500 !important;
    }
    &.hr-tag-2-payroll {
      background: @indigo-100 !important;
      color: @indigo-600 !important;
    }
    &.hr-tag-2-additional {
      color: @yellow-700 !important;
      background: @yellow-200 !important;
    }
    &.hr-tag-2-advance {
      color: @emerald-600 !important;
      background: @emerald-100 !important;
    }
    &.hr-tag-2-severances {
      color: @red-500 !important;
      background: @red-100 !important;
    }
    &.hr-tag-2-transfer {
      color: @emerald-600 !important;
      background: @emerald-100 !important;
    }
    &.hr-tag-2-payroll_transfer {
      color: @pink-800 !important;
      background: @pink-200 !important;
    }
    &.hr-tag-2-advance_transfer {
      color: @emerald-600 !important;
      background: @emerald-100 !important;
    }
    &.hr-tag-2-salary_book {
      color: @teal-600 !important;
      background: @teal-100 !important;
    }
    &.hr-tag-2-month_end_closing {
      background: @indigo-100 !important;
      color: @indigo-500 !important;
    }
    &.hr-tag-2-advance_end_closing {
      color: @emerald-600 !important;
      background: @emerald-100 !important;
    }
    &.hr-tag-2-centralization {
      background: @pink-100 !important;
      color: @pink-500 !important;
    }
    &.hr-tag-2-previred {
      background: @purple-100 !important;
      color: @purple-500 !important;
    }
    &.hr-tag-2-manual_settlement {
      background: @secondary-100 !important;
      color: @secondary-500 !important;
    }
    &.hr-tag-2-calculate_settlement {
      background: @primary-200 !important;
      color: @primary-600 !important;
    }
  }
  &.hr-tag-payroll_dashboard_status {
    &.hr-tag-2-in_queue {
      color: @yellow-700 !important;
      background: @yellow-200 !important;
    }
    &.hr-tag-2-in_progress {
      color: @yellow-700 !important;
      background: @yellow-200 !important;
    }
    &.hr-tag-2-completed {
      background: @emerald-200 !important;
      color: @emerald-600 !important;
    }
    &.hr-tag-2-error {
      color: @red-500 !important;
      background: @red-100 !important;
    }
    &.hr-tag-2-paid {
      background: @emerald-200 !important;
      color: @emerald-600 !important;
    }
    &.hr-tag-2-unpaid {
      color: @purple-700 !important;
      background: @purple-200 !important;
    }
  }
  &.hr-tag-request_status {
    &.hr-tag-2-rejected {
      color: @red-500 !important;
      background: @red-100 !important;
    }
    &.hr-tag-2-accepted {
      color: @emerald-600 !important;
      background: @emerald-100 !important;
    }
    &.hr-tag-2-approved {
      color: @emerald-600 !important;
      background: @emerald-100 !important;
    }
    &.hr-tag-2-pending {
      color: @yellow-700 !important;
      background: @yellow-200 !important;
    }
    &.hr-tag-2-canceled {
      color: @red-500 !important;
      background: @red-100 !important;
    }
  }
  &.hr-tag-absence_type {
    &.hr-tag-2-administrative_day {
      color: @pink-800 !important;
      background: @pink-200 !important;
    }
    &.hr-tag-2-license {
      color: @blue-700 !important;
      background: @blue-100 !important;
    }
    &.hr-tag-2-not_worked {
      color: @red-500 !important;
      background: @red-100 !important;
    }
    &.hr-tag-2-leave {
      color: @purple-700 !important;
      background: @purple-200 !important;
    }
    &.hr-tag-2-others {
      color: @yellow-700 !important;
      background: @yellow-200 !important;
    }
  }
  &.hr-tag-contract_type {
    &.hr-tag-2-indefinite {
      color: @yellow-700 !important;
      background: @yellow-200 !important;
    }
    &.hr-tag-2-fixed {
      color: @emerald-700 !important;
      background: @emerald-200 !important;
    }
    &.hr-tag-2-by_work_or_task {
      color: @indigo-600 !important;
      background: @indigo-100 !important;
    }
    &.hr-tag-2-third_party {
      color: @pink-600 !important;
      background: @pink-100 !important;
    }
    &.hr-tag-2-freelance {
      color: @teal-600 !important;
      background: @teal-100 !important;
    }
    &.hr-tag-2-specific {
      background: @purple-100 !important;
      color: @purple-600 !important;
    }
    &.hr-tag-2-other {
      color: @gray-600 !important;
      background: @gray-100 !important;
    }
  }
  &.hr-tag-expense_policy_status {
    &.hr-tag-2-active {
      color: @emerald-600 !important;
      background: @emerald-100 !important;
    }
    &.hr-tag-2-draft {
      color: @yellow-700 !important;
      background: @yellow-200 !important;
    }
  }
  &.hr-tag-payment_method_enabled {
    &.hr-tag-2-yes {
      color: @emerald-600 !important;
      background: @emerald-100 !important;
    }
    &.hr-tag-2-no {
      color: @yellow-700 !important;
      background: @yellow-200 !important;
    }
  }
  &.hr-tag-boolean,
  &.hr-tag-company_status,
  &.hr-tag-family_emergency_contact {
    &.hr-tag-2-active,
    &.hr-tag-2-yes {
      color: @emerald-600 !important;
      background: @emerald-100 !important;
    }
    &.hr-tag-2-deleted {
      color: @red-500 !important;
      background: @red-100 !important;
    }
    &.hr-tag-2-suspended {
      color: @purple-700 !important;
      background: @purple-200 !important;
    }
    &.hr-tag-2-inactive,
    &.hr-tag-2-no {
      color: @yellow-700 !important;
      background: @yellow-200 !important;
    }
  }
  &.hr-tag-2-accepted {
    color: @success-color !important;
    background: #e0f1e1 !important;
  }
  &.hr-tag-2-rejected {
    color: @red-500 !important;
    background: @red-100 !important;
  }
  &.hr-tag-user_role {
    &.hr-tag-2-admin {
      background: @indigo-100 !important;
      color: @indigo-500 !important;
    }
    &.hr-tag-2-superadmin {
      color: @purple-700 !important;
      background: @purple-200 !important;
    }
    &.hr-tag-2-employee {
      background: @emerald-200 !important;
      color: @emerald-600 !important;
    }
    &.hr-tag-2-manager {
      color: @yellow-700 !important;
      background: @yellow-200 !important;
    }
    &.hr-tag-2-super_manager {
      color: @teal-700 !important;
      background: @teal-200 !important;
    }
  }
  &.hr-tag-status {
    &.hr-tag-2-success {
      color: @success-color !important;
      background: #e0f1e1 !important;
    }
  }
  &.hr-tag-category,
  &.hr-tag-doc_type {
    &.hr-tag-2-empresa {
      background: @blue-200 !important;
      color: @blue-700 !important;
    }
    &.hr-tag-2-trabajador {
      color: @emerald-600 !important;
      background: @emerald-100 !important;
    }
  }
  &.hr-tag-indicator {
    color: rgb(214, 129, 84) !important;
    background: #d6815436 !important;
  }
  &.hr-tag-function {
    background: #f49291 !important;
  }
  &.hr-tag-user_field {
    &.hr-tag-2-text {
      background: @indigo-100 !important;
      color: @indigo-500 !important;
    }
    &.hr-tag-2-date {
      color: @purple-500 !important;
      background: @purple-100 !important;
    }
    &.hr-tag-2-money {
      background: @blue-100 !important;
      color: @blue-500 !important;
    }
    &.hr-tag-2-boolean {
      background: @red-100 !important;
      color: @red-500 !important;
    }
    &.hr-tag-2-number {
      background: @emerald-200 !important;
      color: @emerald-600 !important;
    }
    &.hr-tag-2-percentage {
      background: @pink-100 !important;
      color: @pink-500 !important;
    }
  }
  &.hr-tag-status {
    color: @emerald-600 !important;
    background: @emerald-100 !important;
    &.hr-tag-2-pending {
      color: @yellow-700 !important;
      background: @yellow-200 !important;
    }
    &.hr-tag-2-suspended,
    &.hr-tag-2-inactive {
      color: @indigo-500 !important;
      background: @indigo-100 !important;
    }
    &.hr-tag-2-accepted {
      color: @emerald-600 !important;
      background: @emerald-100 !important;
    }
    &.hr-tag-2-rejected {
      color: @red-500 !important;
      background: @red-100 !important;
    }
  }
  &.hr-tag-dismissal-cause-159 {
    background: @indigo-300 !important;
    color: @indigo-700 !important;
  }
  &.hr-tag-dismissal-cause-160 {
    color: @purple-700 !important;
    background: @purple-200 !important;
  }
  &.hr-tag-dismissal-cause-161 {
    color: @teal-700 !important;
    background: @teal-200 !important;
  }
  &.hr-tag-formula_function {
    background: @indigo-100 !important;
    color: @indigo-500 !important;
  }
  &.hr-tag-variable {
    background: @pink-100 !important;
    color: @pink-500 !important;
  }
  &.hr-tag-money {
    background-color: #21384b !important;
    color: white !important;
  }
  &.hr-tag-institutions {
    &.hr-tag-2-item {
      color: @red-500 !important;
      background: @red-100 !important;
    }
    &.hr-tag-2-ex_caja {
      background: @indigo-100 !important;
      color: @indigo-500 !important;
    }
    &.hr-tag-2-health {
      color: @purple-600 !important;
      background: @purple-100 !important;
    }
    &.hr-tag-2-ccaf {
      color: @teal-600 !important;
      background: @teal-100 !important;
    }
    &.hr-tag-2-mutual {
      background: @pink-100 !important;
      color: @pink-500 !important;
    }
    &.hr-tag-2-afp_comp_seg {
      color: @yellow-600 !important;
      background: @yellow-100 !important;
    }
    &.hr-tag-2-system {
      color: @red-600 !important;
      background: @red-100 !important;
    }
    &.hr-tag-2-pension {
      color: @emerald-600 !important;
      background: @emerald-100 !important;
    }
  }
  &.hr-tag-user_identification_type {
    &.hr-tag-2-rut {
      background: @indigo-100 !important;
      color: @indigo-500 !important;
    }
    &.hr-tag-2-dni {
      background: @blue-100 !important;
      color: @blue-500 !important;
    }
  }
  &.hr-tag-item_category {
    &.hr-tag-2-system {
      background: @pink-100 !important;
      color: @pink-500 !important;
    }
    &.hr-tag-2-normal {
      background: @purple-100 !important;
      color: @purple-600 !important;
    }
  }
  &.hr-tag-item_type {
    &.hr-tag-2-earning {
      background: @indigo-100 !important;
      color: @indigo-500 !important;
    }
    &.hr-tag-2-special_earning {
      background: @blue-100 !important;
      color: @blue-600 !important;
    }
    &.hr-tag-2-legal_deduction {
      background: @emerald-100 !important;
      color: @emerald-500 !important;
    }
    &.hr-tag-2-deduction {
      background: @emerald-100 !important;
      color: @emerald-500 !important;
    }
    &.hr-tag-2-employer_earning {
      background: @red-100 !important;
      color: @red-500 !important;
    }
    &.hr-tag-2-assignation {
      background: @purple-100 !important;
      color: @purple-500 !important;
    }
    &.hr-tag-2-provision {
      background: @pink-100 !important;
      color: @pink-500 !important;
    }
    &.hr-tag-2-other {
      background: @teal-100 !important;
      color: @teal-500 !important;
    }
  }
  border-radius: 3px !important;
  white-space: pre;
  margin-top: 2px;
  color: white !important;
  font-weight: bold;
  line-height: 15px;
  height: 19px;
  padding: 2px 6px !important;
  code {
    background: transparent;
  }
  text-transform: uppercase;
  font-size: 10.5px;
  display: inline-block;
  text-overflow: ellipsis;
}

.ant-tag {
  color: white;
  letter-spacing: 0.2px;
  font-weight: 500 !important;
  line-height: 18.5px !important;
  height: 20px !important;
  font-size: 0.625rem !important;
  text-transform: uppercase;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  code {
    background: transparent;
  }
}

@primary-color: #20384b;