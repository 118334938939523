.user-list-item {
  &.hoverable:hover {
    transform: scale(1.01);
    background: rgba(0, 0, 0, 0.05);
  }
  &.selected {
    background: rgba(0, 0, 0, 0.05);
  }
  width: 100%;
  border-radius: 4px;
  color: #444;
  height: 37px;
  position: relative;
  margin-bottom: 0px;
  padding: 0 2px;
  transition: all 0.2s ease-in-out;
  display: flex;
  .editable-attribute {
    margin: 0px;
    padding: 0px;
    .attribute-value {
      padding: 0px !important;
    }
    /*position: absolute;*/
    /*right: 5px;*/
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
  }
  .user-item-subtext {
    font-size: 10px;
    line-height: 12px;
  }
  .user-avatar {
    margin: 2px;
  }
  .small-avatar {
    transform: scale(0.9);
    margin: 2px 5px 0px 5px;
  }
  .ant-badge-dot {
    bottom: 0px;
    top: initial;
    right: 5px;
  }
  .ant-badge.ant-badge-status {
    margin: 0 10px;
  }
}

@primary-color: #20384b;