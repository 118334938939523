.progress-ring__circle {
  stroke-dasharray: 10 20;
}
.progress-ring__circle {
  transition: stroke-dashoffset 0.35s;
}
.progress-ring__circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(90deg);
  transform-origin: 50% 50%;
}

@primary-color: #20384b;