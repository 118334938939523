/* colors */
/* CAMPUS HR PALETTE */
@hr-yellow: #e2e048;
@hr-green: #b0ce67;
@hr-teal: #18b8d9;
@hr-blue: #239bb4;

@warning-color: #ffc107;
@danger-color: #ef5350;
/* @danger-color: #ff4d4f; */
@avatar-background: #faad14;
@success-color: #66bb6a;
@save-color: #21b781;
/* @success-color: #34C47E; */
/*@body-bg: #F2F2F4;*/
/*@body-bg: #F7FAFC;*/
/*@old-body-bg: #f2f2f4;*/
@body-bg: #f7fafc;

@table-bg: white;
@table-th-bg: #f7f9fa;
@table-th-text: black;
@table-hover-bg: rgb(231, 231, 231);
@vacation-timeline-weekend: @warning-100;
@vacation-timeline-odd-row: #f7f9fa;
@vacation-timeline-cell-border: #eaeaea;

@dark-color: #3f4c6b;
@dark-secondary: #213648;
@card-border: @dark-secondary; /*#3498db*/
@main-blue: @hr-blue;
@dark-text: rgba(0, 0, 0, 0.87);
@dark-primary: #282c34;
@dark-primary-text: white;
@label-color: #888;
@link: @hr-teal;
@selected-color: #ccc;
@button-hover-bg: rgba(55, 53, 47, 0.08);
@inset-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px inset,
  rgba(15, 15, 15, 0.05) 0px 1px 1px inset;
@drop-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
  rgba(15, 15, 15, 0.1) 0 3px 6px, rgba(15, 15, 15, 0.1) 0 9px 24px;
@inset-shadow-error: rgba(255, 77, 79, 0.6) 0px 0px 2px 1px inset,
  rgba(255, 77, 79, 0.6) 0px 1px 2px inset;
@inset-shadow-success: rgba(46, 204, 113, 0.6) 0px 0px 2px 1px inset,
  rgba(46, 204, 113, 0.6) 0px 1px 2px inset;
@shadow-navbar: rgba(40, 42, 49, 0.16) 0px 1px 2px 0px;

@form-bg-no-opacity: #f0f0f0;
@form-bg: #f7f6f5;
/*@form-bg-no-opacity: rgb(247, 247, 245);*/
/*@form-bg: rgba(242, 241, 238, 0.6);*/
/*@form-bg: @form-bg-no-opacity;*/
/* end colors */

/* Mixins */
/* Flex */
.hr-form() {
  /*outline: none !important;*/
  /*border: none !important;*/
  border-radius: 3px !important;
  /*box-shadow: @inset-shadow !important;*/
  background: @form-bg !important;
  border: none !important;
  /*box-shadow: inset 2px 2px 4px rgba(0,0,0,0.04) !important;*/
  box-shadow: none !important;
}

.flex-center() {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  margin: 0 auto;
}
.flex-start() {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  text-align: left !important;
  float: left !important;
  margin-right: auto !important;
}
.flex-end() {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  text-align: right !important;
  float: right !important;
  margin-left: auto !important;
}
.flex-between() {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.flex-around() {
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
}
.flex-evenly() {
  display: flex !important;
  align-items: center !important;
  justify-content: space-evenly !important;
}
.flex-stretch() {
  display: flex !important;
  align-items: stretch !important;
  justify-content: stretch !important;
}

/* DESIGN SYSTEM */
/* PALETTE */
/*BODY BG GRAY*/

@primary-900: #213648;
@primary-800: #1e2533;
@primary-700: #364159;
@primary-600: #354462;
@primary-500: #3f4c6b;
@primary-400: #6679a3;
@primary-300: #8c9bba;
@primary-200: #ccd2e1;
@primary-100: #f0f3f9;

/*SECONDARY BLUE */
/*blue dark sidebar*/
@secondary-900: #071f33;
@secondary-800: #0f3859;
@secondary-700: #19598e;
@secondary-600: #1f70b2;
@secondary-500: #3090df;
@secondary-400: #50a3e5;
@secondary-300: #72b5e9;
@secondary-200: #a7d1f2;
@secondary-100: #dcecfa;

/*PALETA TEXTO*/

@gray-900: #0a0a0a;
@gray-800: #292929;
@gray-700: #3d3d3d;
@gray-600: #5c5c5c;
@gray-500: #7a7a7a;
@gray-400: #999999;
@gray-300: #b8b8b8;
@gray-200: #cccccc;
@gray-100: #ebebeb;

/*PALETA SECUNDARIA*/

/*YELLOW WARNING*/

@warning-900: #3d2e04;
@warning-800: #664d0b;
@warning-700: #a27919;
@warning-600: #dfa726;
@warning-500: #f4b72b;
@warning-400: #fed055;
@warning-300: #feda78;
@warning-200: #fed055;
@warning-100: #fff5d8;

/*DARK YELLOW AVATAR*/

@yellow-900: #281b03;
@yellow-800: #63430b;
@yellow-700: #8b5e14;
@yellow-600: #c78620;
@yellow-500: #f8ac30;
@yellow-400: #fac056;
@yellow-300: #fbcd7a;
@yellow-200: #fcdb9f;
@yellow-100: #fef1d9;

/*HR Blue (Botones inicio de sesión) */

@blue-900: #08709d;
@blue-800: #2481a9;
@blue-700: #4092b5;
@blue-600: #5ca3c1;
@blue-500: #78b4cd;
@blue-400: #93c4d8;
@blue-300: #afd5e4;
@blue-200: #cbe6f0;
@blue-100: #e7f7fc;

/*Light-Blue-Teal*/

@teal-900: #051f25;
@teal-800: #0f4e5b;
@teal-700: #176d80;
@teal-600: #229cb7;
@teal-500: #29b9d7;
@teal-400: #40cbe7;
@teal-300: #71d9ee;
@teal-200: #a6e7f4;
@teal-100: #dbf5fb;

/* RED WARNING (errores)*/

@red-900: #370606;
@red-800: #821012;
@red-700: #b9181b;
@red-600: #e8262a;
@red-500: #ec4949;
@red-400: #ef6d6d;
@red-300: #f39291;
@red-200: #f7b6b5;
@red-100: #fbdadb;

/*GREEN SUCCESS (mensajes éxito)*/

@green-900: #0d1c0d;
@green-800: #28542a;
@green-700: #3c7e3f;
@green-600: #50a855;
@green-500: #66bb6a;
@green-400: #90cb93;
@green-300: #badebc;
@green-200: #c8e6c9;
@green-100: #f1f9f1;

@purple-50: #f5f3ff;
@purple-100: #ede9fe;
@purple-200: #ddd6fe;
@purple-300: #c4b5fd;
@purple-400: #a78bfa;
@purple-500: #8b5cf6;
@purple-600: #7c3aed;
@purple-700: #6d28d9;
@purple-800: #5b21b6;
@purple-900: #4c1d95;

@pink-50: #fdf2f8;
@pink-100: #fce7f3;
@pink-200: #fbcfe8;
@pink-300: #f9a8d4;
@pink-400: #f472b6;
@pink-500: #ec4899;
@pink-600: #db2777;
@pink-700: #be185d;
@pink-800: #9d174d;
@pink-900: #831843;

/*GREEN HOVER*/
@sidebar-hover: #04ffd2;

@emerald-50: #ecfdf5;
@emerald-100: #d1fae5;
@emerald-200: #a7f3d0;
@emerald-300: #6ee7b7;
@emerald-400: #34d399;
@emerald-500: #10b981;
@emerald-600: #059669;
@emerald-700: #047857;
@emerald-800: #065f46;
@emerald-900: #064e3b;

@indigo-50: #eef2ff;
@indigo-100: #e0e7ff;
@indigo-200: #c7d2fe;
@indigo-300: #a5b4fc;
@indigo-400: #818cf8;
@indigo-500: #6366f1;
@indigo-600: #4f46e5;
@indigo-700: #4338ca;
@indigo-800: #3730a3;
@indigo-900: #312e81;
